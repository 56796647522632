import React, { createContext, useContext } from "react";
import _ from "lodash";
import { firestore } from "../firebaseInit";
import {
  doc,
  collection,
  Timestamp,
  runTransaction,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { MessageContext } from "./MessageContext";
import { LoadingContext } from "../contexts/LoadingContext";

export const CommentTransactionContext = createContext();

export const CommentTransactionProvider = (props) => {
  const { currentUser } = useAuth();
  const { setMessage } = useContext(MessageContext);
  const { setIsLoading } = useContext(LoadingContext);

  const addImageComment = async (imageId, newComment) => {
    try {
      setIsLoading(true);

      // create timestamp reference
      const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

      // establish image doc ref that is receiving the comment
      const imageDocRef = doc(firestore, "images", imageId);

      // create doc ref for new comment doc
      const newCommentDocRef = doc(collection(firestore, "comments"));

      // compose comment doc
      const newCommentObject = {
        owner: {
          displayName: currentUser.displayName,
          email: currentUser.email,
        },
        isCensored: false,
        isFlagged: false,
        isResolved: false,
        flaggedDetails: null,
        createdOn: currentTimestamp,
        text: newComment,
        rootImageId: imageId,
        rootCommentId: newCommentDocRef.id,
      };

      await runTransaction(firestore, async (transaction) => {
        // save the new comment to the comment collection
        transaction.set(newCommentDocRef, {
          ...newCommentObject,
        });

        // add the comment to the comment array of the receiving image
        transaction.update(imageDocRef, {
          comments: arrayUnion({
            ...newCommentObject,
          }),
        });
      });

      // share the good news
      setMessage("success", "The comment has been added to the image.", 5000);

      setIsLoading(false);

      return {
        status: "success",
        message: "The comment has been added to the image.",
      };
    } catch (err) {
      setMessage(
        "error",
        `Error attempting to save comment: ${err.message}`,
        12000
      );

      setIsLoading(false);

      return {
        status: "error",
        message: `Error attempting to save comment: ${err.message}`,
      };
    }
  };

  // moderator resolves comment returning it to the gallery and removing future flagging opportunities
  const modResolveFlaggedComment = async (flaggingIncident) => {
    try {
      setIsLoading(true);

      // establish associated image doc ref
      const imageDocRef = doc(firestore, "images", flaggingIncident.imageId);

      // establish incident doc ref
      const incidentDocRef = doc(
        firestore,
        "flaggingIncidents",
        flaggingIncident.id
      );

      // establish comment doc ref
      const commentDocRef = doc(
        firestore,
        "comments",
        flaggingIncident.rootCommentId
      );

      const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

      await runTransaction(firestore, async (transaction) => {
        // reads must be done first, so...
        // fetch the actual image doc data to isolate the specific comment
        const imageDoc = await transaction.get(imageDocRef);
        const imageDocData = imageDoc.data();

        const originalComment = _.find(imageDocData.comments, {
          rootCommentId: flaggingIncident.rootCommentId,
        });

        // update the comment doc
        transaction.update(
          commentDocRef,
          {
            isFlagged: false,
            isCensored: false,
            isResolved: true,
            flaggedDetails: {
              ...originalComment.flaggedDetails,
              moderatedOn: currentTimestamp,
              moderatedBy: currentUser.displayName,
              moderatorAgreed: false,
            },
          },
          { merge: true }
        );

        // remove the original comment from the associated image doc
        transaction.update(imageDocRef, {
          comments: arrayRemove(originalComment),
        });

        transaction.update(imageDocRef, {
          comments: arrayUnion({
            ...originalComment,
            isResolved: true,
            isFlagged: false,
            isCensored: false,
            flaggedDetails: {
              ...originalComment.flaggedDetails,
              moderatedOn: currentTimestamp,
              moderatedBy: currentUser.displayName,
              moderatorAgreed: false,
            },
          }),
        });

        // edit the flagging incident
        transaction.update(
          incidentDocRef,
          {
            moderatedOn: currentTimestamp,
            moderatedBy: currentUser.displayName,
            moderatorAgreed: false,
            isResolved: true,
            isFlagged: false,
            isCensored: false,
          },
          { merge: true }
        );
      });

      setMessage("success", "The item has been restored to the Gallery.", 5000);

      setIsLoading(false);

      return {
        status: "success",
        message: "The item has been restored to the Gallery.",
      };
    } catch (err) {
      setMessage(
        "error",
        `Error attempting to restore the item: ${err.message}`,
        12000
      );

      setIsLoading(false);

      return {
        status: "error",
        message: `Error attempting to restore the item: ${err.message}`,
      };
    }
  };

  // staging an image allows it to be discovered and processed as retained evidence
  // in the event such a thing is neccesary.
  const stageCommentForRemoval = async (flaggingIncident) => {
    try {
      setIsLoading(true);

      // establish associated image doc ref
      const imageDocRef = doc(firestore, "images", flaggingIncident.imageId);

      // establish incident doc ref
      const incidentDocRef = doc(
        firestore,
        "flaggingIncidents",
        flaggingIncident.id
      );

      // establish comment doc ref
      const commentDocRef = doc(
        firestore,
        "comments",
        flaggingIncident.rootCommentId
      );

      const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

      await runTransaction(firestore, async (transaction) => {
        // reads must be done first, so...
        // fetch the actual image doc data to isolate the specific comment
        const imageDoc = await transaction.get(imageDocRef);
        const imageDocData = imageDoc.data();

        const originalComment = _.find(imageDocData.comments, {
          rootCommentId: flaggingIncident.rootCommentId,
        });

        // update the comment doc
        transaction.update(
          commentDocRef,
          {
            isFlagged: false,
            isCensored: true,
            isResolved: true,
            flaggedDetails: {
              ...originalComment.flaggedDetails,
              moderatedOn: currentTimestamp,
              moderatedBy: currentUser.displayName,
              moderatorAgreed: true,
              reason: flaggingIncident.reason,
            },
          },
          { merge: true }
        );

        // remove the original comment from the associated image doc
        transaction.update(imageDocRef, {
          comments: arrayRemove(originalComment),
        });

        transaction.update(imageDocRef, {
          comments: arrayUnion({
            ...originalComment,
            isResolved: true,
            isFlagged: false,
            isCensored: true,
            flaggedDetails: {
              ...originalComment.flaggedDetails,
              moderatedOn: currentTimestamp,
              moderatedBy: currentUser.displayName,
              moderatorAgreed: true,
              reason: flaggingIncident.reason,
            },
          }),
        });

        // edit the flagging incident
        transaction.update(
          incidentDocRef,
          {
            moderatedOn: currentTimestamp,
            moderatedBy: currentUser.displayName,
            moderatorAgreed: true,
            isResolved: true,
            isFlagged: false,
            isCensored: true,
            reason: flaggingIncident.reason,
          },
          { merge: true }
        );
      });

      setMessage(
        "success",
        "The item has been permanently removed from the gallery.",
        5000
      );

      setIsLoading(false);

      return {
        status: "success",
        message: "The item has been permanently removed from the gallery.",
      };
    } catch (err) {
      setMessage(
        "error",
        `Error attempting to permanently remove the item from the gallery: ${err.message}`,
        12000
      );

      setIsLoading(false);

      return {
        status: "error",
        message: `Error attempting to permanently remove the item from the gallery: ${err.message}`,
      };
    }
  };

  // destroys comment and its related flaggingIncident, if applicable,
  // as well as comment instance within associated image
  const destroyComment = async (destructionData) => {
    // all we need is the comment doc itself, and we have everything we need
    // the comment id will be found in the destruction data in the same place
    // regardless of the source of the destroy request
    const commentDocRef = doc(
      firestore,
      "comments",
      destructionData.rootCommentId
    );

    try {
      await runTransaction(firestore, async (transaction) => {
        // setup stores for required data
        let incidentDocRef = null;
        let incidentDoc = null;
        let imageDocRef = null;

        // read ops must come first, so...
        const commentDoc = await transaction.get(commentDocRef);
        const comment = commentDoc.data();

        // check for flaggedDetails
        if (comment.flaggedDetails && comment.flaggedDetails !== null) {
          incidentDocRef = doc(
            firestore,
            "flaggingIncidents",
            comment.flaggedDetails.rootIncidentId
          );

          incidentDoc = await transaction.get(incidentDocRef);
        }

        // remove the incident doc, if needed
        if (incidentDoc !== null && incidentDoc.exists()) {
          transaction.delete(incidentDocRef);
        }

        imageDocRef = doc(firestore, "images", comment.rootImageId);

        // remove the comment from the associated image
        transaction.update(imageDocRef, {
          comments: arrayRemove(comment),
        });

        // delete the comment itself
        transaction.delete(commentDocRef);
      });

      // share the good news
      setMessage("success", "The comment has been deleted successfully.", 5000);

      setIsLoading(false);

      return {
        status: "success",
        message: "The comment has been deleted successfully.",
      };
    } catch (err) {
      setMessage(
        "error",
        `Error attempting to delete the comment: ${err.message}`,
        12000
      );

      setIsLoading(false);

      return {
        status: "error",
        message: `Error attempting to delete the comment: ${err.message}`,
      };
    }
  };

  // flag a comment for moderator review
  const flagComment = async (imageDoc, originalComment, flaggedDetails) => {
    try {
      setIsLoading(true);

      const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

      // this calls for a new incident, so...
      const newIncidentRef = doc(collection(firestore, "flaggingIncidents"));

      // establish doc ref for original comment
      const originalCommentRef = doc(
        firestore,
        "comments",
        originalComment.rootCommentId
      );

      // establish a doc ref for the associated image
      const imageDocRef = doc(firestore, "images", imageDoc.id);

      await runTransaction(firestore, async (transaction) => {
        // update the original comment with the flagging data
        transaction.update(
          originalCommentRef,
          {
            isFlagged: true,
            isCensored: true,
            flaggedDetails: {
              flaggerDisplayName: flaggedDetails.displayName,
              flaggerEmail: flaggedDetails.email,
              reason: flaggedDetails.reason,
              createdOn: currentTimestamp,
              rootIncidentId: newIncidentRef.id,
            },
          },
          { merge: true }
        );

        // remove the original comment from the image's comments array
        transaction.update(imageDocRef, {
          comments: arrayRemove({
            ...originalComment,
          }),
        });

        // add the updated comment back into the images's comments array
        transaction.update(imageDocRef, {
          comments: arrayUnion({
            ...originalComment,
            isFlagged: true,
            isCensored: true,
            flaggedDetails: {
              flaggerDisplayName: flaggedDetails.displayName,
              flaggerEmail: flaggedDetails.email,
              reason: flaggedDetails.reason,
              createdOn: currentTimestamp,
              rootIncidentId: newIncidentRef.id,
            },
          }),
        });

        // create the new flagging incident
        transaction.set(newIncidentRef, {
          type: "comment",
          comment: originalComment.text,
          rootCommentId: originalComment.rootCommentId,
          imageLink: imageDoc.imageLink,
          owner: originalComment.owner,
          isResolved: false,
          isFlagged: true,
          imageId: imageDoc.id,
          imageDescription: imageDoc.description,
          flaggerDisplayName: flaggedDetails.displayName,
          flaggerEmail: flaggedDetails.email,
          reason: flaggedDetails.reason,
          createdOn: currentTimestamp,
        });
      });

      // share the good news
      setMessage(
        "success",
        "The comment has been flagged and will be hidden from the image awaiting review from a moderator.",
        7000
      );

      setIsLoading(false);

      return {
        status: "success",
        message: "Successfully flagged the comment.",
      };
    } catch (err) {
      setMessage(
        "error",
        `Error attempting to flag the comment: ${err.message}`,
        12000
      );

      setIsLoading(false);

      return {
        status: "error",
        message: `Error attempting to flag the comment: ${err.message}`,
      };
    }
  };

  return (
    <CommentTransactionContext.Provider
      value={{
        stageCommentForRemoval,
        modResolveFlaggedComment,
        addImageComment,
        flagComment,
        destroyComment,
      }}
    >
      {props.children}
    </CommentTransactionContext.Provider>
  );
};
