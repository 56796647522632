import React, { useEffect } from "react";
import { Typography, Grid, Container, Divider, Button } from "@mui/material";
import useGalleryWall from "../hooks/useGalleryWall";
import ImageSetDisplayPanel from "./ImageSetDisplayPanel";

const GalleryWall = () => {
  const {
    loading,
    fetchedImages,
    fetchInitialImageSet,
    imageCache,
    fetchMoreImages,
    endIsReached,
    previousIsPossible,
    loadPreviousSet,
    handleGalleryRefresh,
  } = useGalleryWall();

  useEffect(() => {
    fetchInitialImageSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid item container direction="column" sx={{ marginTop: "3em" }}>
        {!loading &&
        fetchedImages &&
        fetchedImages.length &&
        fetchedImages.length > 0 ? (
          <>
            <Grid item container direction="row" justifyContent="space-around">
              <Grid item>
                <Button
                  variant="contained"
                  onClick={loadPreviousSet}
                  disabled={!previousIsPossible}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={fetchMoreImages}
                  disabled={endIsReached}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          </>
        ) : null}

        {imageCache && imageCache.length && imageCache.length > 0 ? (
          <Grid item align="center">
            <Button
              size="large"
              variant="outlined"
              onClick={handleGalleryRefresh}
            >
              {imageCache.length === 1
                ? `${imageCache.length} new image...`
                : `${imageCache.length} new images...`}
            </Button>
          </Grid>
        ) : null}
        {!loading && fetchedImages && fetchedImages.length > 0 ? (
          <ImageSetDisplayPanel imageSet={fetchedImages} />
        ) : !loading && fetchedImages && fetchedImages.length === 0 ? (
          <Grid item align="center">
            <Typography sx={{ marginTop: "1em", fontSize: "70px" }}>
              There are no images in the Gallery...
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      {imageCache && imageCache.length && imageCache.length > 0 ? (
        <Grid item align="center">
          <Button
            size="large"
            variant="outlined"
            onClick={handleGalleryRefresh}
          >
            {imageCache.length === 1
              ? `${imageCache.length} new image...`
              : `${imageCache.length} new images...`}
          </Button>
        </Grid>
      ) : null}

      {!loading &&
      fetchedImages &&
      fetchedImages.length &&
      fetchedImages.length > 0 ? (
        <>
          <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          <Grid item container direction="row" justifyContent="space-around">
            <Grid item>
              <Button
                variant="contained"
                onClick={loadPreviousSet}
                disabled={!previousIsPossible}
              >
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={fetchMoreImages}
                disabled={endIsReached}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Container>
  );
};

export default GalleryWall;
