import { useState, useContext } from "react";
import { firestore } from "../firebaseInit";
import { doc, onSnapshot } from "firebase/firestore";
import { ImageTransactionContext } from "../contexts/ImageTransactionContext";

// global for listener object, when active
let unsub = null;

const useRandomImage = () => {
  const { fetchRandomImage } = useContext(ImageTransactionContext);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const getRandomImage = async () => {
    setLoading(true);
    const fetchedRandomImage = await fetchRandomImage();
    establishListener(fetchedRandomImage);
    setLoading(false);
  };

  // creates listener for the fetched random image
  const establishListener = (fetchedRandomImage) => {
    unsub = onSnapshot(
      doc(firestore, "images", fetchedRandomImage.id),
      (doc) => {
        setImage({ id: doc.id, ...doc.data() });
      }
    );
  };

  const setupImageListener = async () => {
    setLoading(true);
    await getRandomImage();
    setLoading(false);
  };

  const unloadListener = () => {
    if (unsub) {
      unsub();
    }
  };

  return {
    loading,
    image,
    unloadListener,
    setupImageListener,
  };
};

export default useRandomImage;
