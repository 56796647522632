import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ImageTransactionContext } from "../contexts/ImageTransactionContext";
import { CommentTransactionContext } from "../contexts/CommentTransactionContext";

const useImageDetail = () => {
  const { deleteImage, flagImage } = useContext(ImageTransactionContext);

  const { flagComment, addImageComment, destroyComment } = useContext(
    CommentTransactionContext
  );

  const history = useHistory();

  const handleFlagContent = async (
    imageDoc,
    flaggedDetails,
    comment = null
  ) => {
    if (
      comment && comment.rootImageId
        ? await flagComment(imageDoc, comment, flaggedDetails)
        : await flagImage(imageDoc, flaggedDetails)
    )
      history.push(`/home`);
  };

  const handleRemoveImage = async (imageDoc) => {
    await deleteImage(imageDoc);
  };

  const handleAddComment = async (imageDoc, newComment) => {
    await addImageComment(imageDoc, newComment);
  };

  const handleRemoveComment = async (comment) => {
    await destroyComment(comment);
  };

  return {
    handleRemoveImage,
    handleFlagContent,
    handleAddComment,
    handleRemoveComment,
  };
};

export default useImageDetail;
