import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../contexts/AuthContext";
import { useTheme, styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { LoadingContext } from "../contexts/LoadingContext";
import TermIcon from "@mui/icons-material/CardMembership";

const LoadingDialog = () => {
  const history = useHistory();
  const theme = useTheme();

  const { updatePolicyAcceptanceStatus, googleSignOut } = useAuth();

  const { isLoading, isAgreementOpen, setIsAgreementOpen } =
    useContext(LoadingContext);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const ResponsibleUseItemBlock = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const handleAgreementAccept = async () => {
    // edit user property regarding agreement acceptance
    await updatePolicyAcceptanceStatus(true);

    setIsAgreementOpen(false);
  };

  const handleAgreementDeny = async () => {
    // edit user property regarding agreement acceptance
    await updatePolicyAcceptanceStatus(false);

    setIsAgreementOpen(false);

    // signout user
    await googleSignOut();
    history.push("/");
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={isLoading}
        aria-labelledby="responsive-dialog-loading"
      >
        <DialogTitle id="responsive-dialog-loading" align="center">
          {"Working..."}
        </DialogTitle>
        <DialogContent align="center">
          <CircularProgress
            size="60px"
            thickness={4}
            sx={{ color: (theme) => theme.palette.secondary.light }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={isAgreementOpen}
        aria-labelledby="responsive-dialog-agreement"
      >
        <DialogTitle
          id="responsive-dialog-agreement"
          align="center"
          sx={{ fontSize: "30px" }}
        >
          {"Greenhouse Gallery Responsible Use Agreement"}
        </DialogTitle>
        <DialogContent align="left">
          <Typography
            gutterBottom
            sx={{ fontSize: "20px", marginBottom: "2em" }}
          >
            By accepting this responsible use agreement, you are stating that
            you understand and accept the following terms:
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Terms of Responsible Use
              </Typography>
              <ResponsibleUseItemBlock>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.secondary.dark,
                        }}
                      >
                        <TermIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="All of the actions that I perform on the Gallery can be traced
                      back to my specific user account."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.secondary.dark,
                        }}
                      >
                        <TermIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="All content that I post here must adhere to the code of conduct as
                      outlined by my school."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.secondary.dark,
                        }}
                      >
                        <TermIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Moderators can determine, at any time, that content posted is not
                      appropriate for the Gallery and remove it."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.secondary.dark,
                        }}
                      >
                        <TermIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="If I engage in criminal behavior with my access to this Gallery,
                      the school and local law enforcement can and will get involved."
                    />
                  </ListItem>
                </List>
              </ResponsibleUseItemBlock>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleAgreementAccept}>
            Agree
          </Button>
          <Button variant="outlined" onClick={handleAgreementDeny}>
            Disagree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LoadingDialog;
