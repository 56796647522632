import { useState, useEffect } from "react";
import { firestore } from "../firebaseInit";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

const useAccessPanel = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [foundUser, setFoundUser] = useState(null);
  const [searchError, setSearchError] = useState("");

  let unsub = null;

  const establishUserListener = async () => {
    const docRef = doc(firestore, "users", searchTerm);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // generate snapshot listener for this user
      unsub = onSnapshot(docRef, (doc) => {
        setFoundUser({ id: doc.id, ...doc.data() });
      });
    } else {
      unsub = null;
      setSearchError("The provided email was not found.");
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      establishUserListener();
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleEmailSearch = async (email) => {
    setSearchTerm(email);
  };

  return {
    handleEmailSearch,
    searchError,
    setSearchError,
    foundUser,
  };
};

export default useAccessPanel;
