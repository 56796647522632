import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Container, Typography, Button, Divider } from "@mui/material";
import { NavContext } from "../contexts/NavContext";
import CloseModPanelIcon from "@mui/icons-material/CancelTwoTone";
import ModeratorPanelItem from "./ModeratorPanelItem";

const ModeratorPanel = ({
  flaggedItems,
  flaggedItemsCache,
  loading,
  mergeFlaggedItems,
}) => {
  const { setNavState } = useContext(NavContext);
  const history = useHistory();

  useEffect(() => {
    if (
      !loading &&
      flaggedItemsCache &&
      flaggedItemsCache.length &&
      flaggedItemsCache.length > 0
    ) {
      mergeFlaggedItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleModeratorPanelClose = () => {
    setNavState({ accessPanelIsOpen: false });
    setNavState({ evidencePanelIsOpen: false });
    setNavState({ modPanelIsOpen: false });
    history.push("/");
  };
  return (
    <Container>
      <Grid item container direction="column">
        <Grid item align="right" sx={{ marginTop: "10px" }}>
          <CloseModPanelIcon
            onClick={handleModeratorPanelClose}
            sx={{ fontSize: "40px", cursor: "pointer" }}
          />
        </Grid>
        <Grid item align="center">
          <Typography gutterBottom variant="h2">
            Moderator Panel
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ fontSize: "20px" }}>
            Welcome to the Moderator Panel. As a moderator you perform a
            valuable service to the Gallery, maintaining the integrity of the
            content and the safety of our participants. Thank You.
          </Typography>
        </Grid>
        <Divider
          sx={{ marginTop: "10px", marginBottom: "10px" }}
          variant="middle"
        />
        {flaggedItemsCache &&
        flaggedItemsCache.length &&
        flaggedItemsCache.length > 0 ? (
          <Grid item align="center">
            <Button size="large" variant="outlined" onClick={mergeFlaggedItems}>
              {flaggedItemsCache.length === 1
                ? `1 new item...`
                : `${flaggedItemsCache.length} new items...`}
            </Button>
          </Grid>
        ) : null}
        {flaggedItems && flaggedItems.length && flaggedItems.length > 0 ? (
          flaggedItems.map((flaggedItem) => (
            <ModeratorPanelItem
              key={flaggedItem.id}
              flaggedItem={flaggedItem}
            />
          ))
        ) : (
          <Grid item sx={{ marginTop: "30px" }} align="center">
            <Typography variant="body1" sx={{ fontSize: "18px" }}>
              There are no flagged Gallery items at the moment...
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item align="center" sx={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleModeratorPanelClose}
        >
          Close Moderator Panel
        </Button>
      </Grid>
    </Container>
  );
};

export default ModeratorPanel;
