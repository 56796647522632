import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  Typography,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import { NavContext } from "../contexts/NavContext";
import useImageSearch from "../hooks/useImageSearch";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CloseImageSearchIcon from "@mui/icons-material/CancelTwoTone";
import ImageSetDisplayPanel from "./ImageSetDisplayPanel";

const ImageSearch = () => {
  const { foundImages, findImages, searchResult } = useImageSearch();
  const { setNavState } = useContext(NavContext);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");

  const handleImageSearchClose = () => {
    setNavState({ searchActive: false });
    history.push("/");
  };

  const handleSearch = () => {
    if (searchTerm && searchTerm.length > 0) {
      const loweredSearchTerm = searchTerm.toLowerCase();
      setSearchTerm(loweredSearchTerm);
      findImages(loweredSearchTerm);
    }
  };

  return (
    <Container fixed>
      <Grid item container direction="column">
        <Grid item align="right" sx={{ marginTop: "10px" }}>
          <CloseImageSearchIcon
            onClick={handleImageSearchClose}
            sx={{ fontSize: "40px", cursor: "pointer" }}
          />
        </Grid>
        <Grid item align="center">
          <Typography gutterBottom variant="h2">
            Image Search
          </Typography>
        </Grid>
        <Grid item align="center">
          <Typography variant="h6">
            You can search images by the tags that were assigned to them...
          </Typography>
        </Grid>
        <Divider
          sx={{ marginTop: "30px", marginBottom: "25px" }}
          variant="middle"
        />
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <TextField
              id="input-with-icon-textfield"
              label="Enter Search Tag"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && searchTerm.length > 0) {
                  handleSearch();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ImageSearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSearch}
              disabled={searchTerm.length === 0}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        {searchResult && searchResult.length > 0 ? (
          <Grid
            item
            sx={{ marginTop: "0.5em", fontStyle: "italic" }}
            align="center"
          >
            <Typography variant="body2">{searchResult}</Typography>
          </Grid>
        ) : null}
      </Grid>

      <Grid item align="center" sx={{ marginTop: "20px" }}>
        {foundImages && foundImages.length > 0 && (
          <ImageSetDisplayPanel imageSet={foundImages} />
        )}
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleImageSearchClose}
        >
          Close Image Search
        </Button>
      </Grid>
    </Container>
  );
};

export default ImageSearch;
