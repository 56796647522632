import React from "react";
import galleryLogo from "../assets/galleryLogo.png";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const features = [
  {
    key: 1,
    heading: "A Walled-Garden...",
    content:
      "Only Aurora staff and learners can actively contribute and interact with the AI-moderated Gallery. We must practice with online social skills in order to become responsible, effective digital citizens. Having a walled-garden environment within which to practice is ideal.",
  },
  {
    key: 2,
    heading: "No Hiding...",
    content:
      "We can block our learners from the challenges of the connected world or help them develop the online skills they will need for the rest of their lives. Preparing our learners for a connected world requires providing them with authentic, engaging experiences that nurture these skills.",
  },
  {
    key: 3,
    heading: "Meaning and Togetherness...",
    content:
      "Images are a powerful element in the way we communicate across all cultures and societies. The gallery is an experience where staff and learners can collectively find and share meaning through images.",
  },
];

const HeroTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: 60,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 80,
  },
  fontWeight: 900,
  fontFamily: "Architects Daughter",
  color: theme.palette.primary.main,
  lineHeight: "0.80em",
  marginTop: "15px",
  marginBottom: "25px",
}));

const MantraTypography = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  fontWeight: 900,
  fontFamily: "Architects Daughter",
  color: theme.palette.secondary.main,
}));

const LeadTypography = styled(Typography)(({ theme }) => ({
  fontSize: 25,
  fontWeight: 900,
  fontFamily: "Architects Daughter",
  color: theme.palette.secondary.dark,
}));

const LandingPage = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Container style={{ marginTop: "8em" }}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Avatar
              alt="logo"
              src={galleryLogo}
              sx={{
                width: matchesSm ? "10em" : "15em",
                height: matchesSm ? "10em" : "15em",
              }}
            />
          </Grid>
          <Grid item align="center">
            <HeroTypography>Greenhouse Gallery</HeroTypography>
          </Grid>
          <Grid item align="center">
            <MantraTypography>
              Nurturing digital skills and behaviors through an authentic,
              collaborative social network built around images
            </MantraTypography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" sx={{ marginTop: "3.5em" }}>
        <Grid container spacing={4}>
          {features.map((card) => (
            <Grid item key={card.key} md={4} sm={12}>
              <Card>
                <CardContent>
                  <LeadTypography gutterBottom variant="h5" component="h2">
                    {card.heading}
                  </LeadTypography>
                  <Typography variant="body1">{card.content}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default LandingPage;
