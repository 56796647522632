import React, { useReducer, createContext } from "react";

export const NavContext = createContext();

const SET_NAV = "SET_NAV";
const RESET_NAV = "RESET_NAV";

const navReducer = (state, action) => {
  switch (action.type) {
    case SET_NAV:
      if (action.payload.hasOwnProperty("sortStrategy")) {
        return { ...state, sortStrategy: action.payload.sortStrategy };
      } else if (action.payload.hasOwnProperty("randomizerOn")) {
        return { ...state, randomizerOn: action.payload.randomizerOn };
      } else if (action.payload.hasOwnProperty("imageDetailsActive")) {
        return {
          ...state,
          imageDetailsActive: action.payload.imageDetailsActive,
        };
      } else if (action.payload.hasOwnProperty("addImageActive")) {
        return { ...state, addImageActive: action.payload.addImageActive };
      } else if (action.payload.hasOwnProperty("randomRefresh")) {
        return { ...state, randomRefresh: action.payload.randomRefresh };
      } else if (action.payload.hasOwnProperty("modPanelIsOpen")) {
        return { ...state, modPanelIsOpen: action.payload.modPanelIsOpen };
      } else if (action.payload.hasOwnProperty("evidencePanelIsOpen")) {
        return {
          ...state,
          evidencePanelIsOpen: action.payload.evidencePanelIsOpen,
        };
      } else if (action.payload.hasOwnProperty("accessPanelIsOpen")) {
        return {
          ...state,
          accessPanelIsOpen: action.payload.accessPanelIsOpen,
        };
      } else if (action.payload.hasOwnProperty("collectionActive")) {
        return {
          ...state,
          collectionActive: action.payload.collectionActive,
        };
      } else if (action.payload.hasOwnProperty("searchActive")) {
        return {
          ...state,
          searchActive: action.payload.searchActive,
        };
      } else {
        return { ...state };
      }

    case RESET_NAV:
      return { ...action.payload };

    default:
      return state;
  }
};

export const NavContextProvider = (props) => {
  const initialState = {
    sortStrategy: "latest",
    randomizerOn: false,
    imageDetailsActive: false,
    addImageActive: false,
    randomRefresh: false,
    modPanelIsOpen: false,
    evidencePanelIsOpen: false,
    accessPanelIsOpen: false,
    collectionActive: false,
    searchActive: false,
  };
  const [state, dispatch] = useReducer(navReducer, initialState);

  const setNavState = (navPayload) => {
    dispatch({
      type: SET_NAV,
      payload: navPayload,
    });
  };

  const resetNavState = () => {
    dispatch({
      type: RESET_NAV,
      payload: initialState,
    });
  };

  return (
    <NavContext.Provider
      value={{
        navState: state,
        setNavState,
        resetNavState,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
};
