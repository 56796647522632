import React, { useState } from "react";

import { useAuth } from "../contexts/AuthContext";
import {
  IconButton,
  Tooltip,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import useImageDetail from "../hooks/useImageDetail";

const FlagContent = ({ currentImage, comment }) => {
  const [flagOpen, setFlagOpen] = useState(false);
  const [removalDetails, setRemovalDetails] = useState("");
  const [removalDetailsError, setRemovalDetailsError] = useState("");
  const { handleFlagContent } = useImageDetail();
  const { currentUser } = useAuth();
  const handleFlagOpen = () => {
    setFlagOpen(true);
  };

  const handleFlagSubmit = () => {
    if (removalDetails.length === 0) {
      return setRemovalDetailsError(
        "We need some details here before flagging an item for removal from the gallery..."
      );
    } else {
      handleFlagContent(
        currentImage,
        {
          displayName: currentUser.displayName,
          email: currentUser.email,
          reason: removalDetails,
        },
        comment
      );
      handleFlagClose();
    }
  };

  const handleFlagClose = () => {
    setRemovalDetails("");
    setRemovalDetailsError("");
    setFlagOpen(false);
  };

  return (
    <>
      <Tooltip title="Flag Item for Removal" placement="top">
        <IconButton
          onClick={handleFlagOpen}
          sx={{ color: "red" }}
          aria-label="flag-item"
          component="span"
        >
          <FlagIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={flagOpen}>
        <DialogTitle>Flag Content for Removal</DialogTitle>
        <DialogContent>
          <Grid item container direction="row">
            <Grid item xs={5} sm={4}>
              <img
                src={`${currentImage.imageLink}`}
                alt={currentImage.description}
                width="150px"
              />
            </Grid>
            <Grid item xs={7} sm={8}>
              <DialogContentText>
                Please provide details concerning why this item should be
                removed from the gallery. This action will remove the item from
                the gallery until it is reviewed by a moderator:
              </DialogContentText>
            </Grid>
          </Grid>
          <TextField
            error={removalDetailsError.length !== 0}
            helperText={removalDetailsError}
            autoFocus
            multiline
            onChange={(e) => setRemovalDetails(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleFlagSubmit();
              }
            }}
            margin="dense"
            id="reason"
            label="Flag Details"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              handleFlagClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleFlagSubmit}>
            Flag Item
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FlagContent;
