import React from "react";
import ReactDOM from "react-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MessageProvider } from "./contexts/MessageContext";
import { ImageTransactionProvider } from "./contexts/ImageTransactionContext";
import { CommentTransactionProvider } from "./contexts/CommentTransactionContext";
import AuthContextProvider from "./contexts/AuthContext";
import { LoadingProvider } from "./contexts/LoadingContext";
import { NavContextProvider } from "./contexts/NavContext";
import App from "./components/App";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8C8C94",
      dark: "#485B5E",
      contrastText: "#ECEDF4",
    },
    secondary: {
      main: "#8BB394",
      light: "#C9DCD1",
      dark: "#486554",
      contrastText: "#FFF",
    },
    background: {
      default: "#ECEDF4",
    },
  },
  typography: {
    heroText: {
      fontFamily: "Architects Daughter",
      fontWeight: 900,
      fontSize: "100px",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MessageProvider>
      <LoadingProvider>
        <NavContextProvider>
          <AuthContextProvider>
            <ImageTransactionProvider>
              <CommentTransactionProvider>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </CommentTransactionProvider>
            </ImageTransactionProvider>
          </AuthContextProvider>
        </NavContextProvider>
      </LoadingProvider>
    </MessageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
