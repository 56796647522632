import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Grid, Typography, Button } from "@mui/material";
import { NavContext } from "../contexts/NavContext";
import ImageDetail from "./ImageDetail";
import CommentPanel from "./CommentPanel";

const ImageDetails = ({ currentImage, error, loading }) => {
  const history = useHistory();
  const { navState, setNavState } = useContext(NavContext);

  return (
    <Container>
      <Grid item container direction='column' sx={{ marginBottom: "2em" }}>
        <Grid
          item
          container
          direction='row'
          justifyContent='center'
          spacing={2}
        >
          <Grid item>
            <Button
              variant='outlined'
              onClick={() => {
                if (navState.randomizerOn === true) {
                  setNavState({ randomizerOn: false });
                } else {
                  setNavState({ imageDetailsActive: false });
                  history.push("/home");
                }
              }}
            >
              Back to Gallery
            </Button>
          </Grid>
          {navState.randomizerOn === true && (
            <Grid item>
              <Button
                variant='outlined'
                onClick={() => {
                  setNavState({ randomRefresh: true });
                  history.push("/home");
                }}
              >
                Another Random Image
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {!loading && currentImage ? (
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <ImageDetail currentImage={currentImage} />
          </Grid>
          <Grid item md={6} sm={12}>
            <CommentPanel currentImage={currentImage} />
          </Grid>
        </Grid>
      ) : (
        <Typography variant='body2'>LOADING...</Typography>
      )}
    </Container>
  );
};

export default ImageDetails;
