import React, { useContext, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import formatDate from "../utils/dateFormatter";
import { useAuth } from "../contexts/AuthContext";
import { ImageTransactionContext } from "../contexts/ImageTransactionContext";
import { CommentTransactionContext } from "../contexts/CommentTransactionContext";

const ModeratorPanelItem = ({ flaggedItem }) => {
  const { currentUser } = useAuth();
  const { stageImageForRemoval, modResolveFlaggedImage } = useContext(
    ImageTransactionContext
  );
  const { stageCommentForRemoval, modResolveFlaggedComment } = useContext(
    CommentTransactionContext
  );

  const [removalDetails, setRemovalDetails] = useState("");
  const [removalDetailsError, setRemovalDetailsError] = useState("");
  const [removeItemPanelOpen, setRemoveItemPanelOpen] = useState(false);

  const handleRemoveItem = (originalFlaggedDetails = "") => {
    // carry over the flagged details for the removal details
    setRemovalDetails(originalFlaggedDetails.reason);

    setRemoveItemPanelOpen(true);
  };

  return (
    <Paper
      key={flaggedItem.id}
      elevation={2}
      sx={{ marginTop: "5px", marginBottom: "5px", padding: "10px" }}
    >
      {flaggedItem.type === "comment" ? (
        <Grid item container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <img
              src={`${flaggedItem.imageLink}`}
              alt={flaggedItem.description}
              width="200px"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>
                  {`${flaggedItem.owner.displayName} (${
                    flaggedItem.owner.email
                  }) posted this comment to the Gallery on ${formatDate(
                    flaggedItem.createdOn
                  )}`}
                </Typography>
                <Grid item>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    {`Comment: ${flaggedItem.comment}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" sx={{ marginTop: "5px" }}>
                <Grid item>
                  <Typography sx={{ fontSize: "13px" }}>{`Flagged by ${
                    flaggedItem.flaggerDisplayName
                  }, on ${formatDate(flaggedItem.createdOn)}`}</Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ fontSize: "15px", fontWeight: "bold" }}
                  >{`Reason: ${flaggedItem.reason}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => handleRemoveItem(flaggedItem)}
                >
                  Remove Comment
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => modResolveFlaggedComment(flaggedItem)}
                >
                  Resolve as Safe
                </Button>
              </Grid>
              <Dialog
                open={removeItemPanelOpen}
                onClose={() => setRemoveItemPanelOpen(false)}
              >
                <DialogTitle>Comment Removal</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {flaggedItem.owner.email === currentUser.email
                      ? `By removing this comment, we are asserting that the content violates the code of conduct for our institution.`
                      : `Please provide details concerning why this comment is being removed from the gallery:`}
                  </DialogContentText>
                  {flaggedItem.owner.email !== currentUser.email && (
                    <TextField
                      error={removalDetailsError.length !== 0}
                      helperText={removalDetailsError}
                      value={removalDetails}
                      autoFocus
                      multiline
                      onChange={(e) => setRemovalDetails(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (removalDetails.length === 0) {
                            setRemovalDetailsError(
                              "We need some details here before removing a comment from the gallery..."
                            );
                          } else {
                            stageCommentForRemoval({
                              ...flaggedItem,
                              reason: removalDetails,
                            });
                            setRemovalDetails("");
                            setRemovalDetailsError("");
                            setRemoveItemPanelOpen(false);
                          }
                        }
                      }}
                      margin="dense"
                      id="reason"
                      label="Removal Details"
                      type="text"
                      fullWidth
                      variant="standard"
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setRemovalDetails("");
                      setRemovalDetailsError("");
                      setRemoveItemPanelOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      if (removalDetails.length === 0) {
                        setRemovalDetailsError(
                          "We need some details here before removing a comment from the gallery..."
                        );
                      } else {
                        stageCommentForRemoval({
                          ...flaggedItem,
                          reason: removalDetails,
                        });
                        setRemovalDetails("");
                        setRemovalDetailsError("");
                        setRemoveItemPanelOpen(false);
                      }
                    }}
                  >
                    Remove Comment
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <img
              src={`${flaggedItem.imageLink}`}
              alt={flaggedItem.description}
              width="200px"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>{`${
                  flaggedItem.owner.displayName
                } (${
                  flaggedItem.owner.email
                }) posted this image to the Gallery on ${formatDate(
                  flaggedItem.createdOn
                )}`}</Typography>
              </Grid>
              <Grid item container direction="column" sx={{ marginTop: "5px" }}>
                <Grid item>
                  <Typography sx={{ fontSize: "13px" }}>{`Flagged by ${
                    flaggedItem.flaggerDisplayName
                  }, on ${formatDate(flaggedItem.createdOn)}`}</Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ fontSize: "15px", fontWeight: "bold" }}
                  >{`Reason: ${flaggedItem.reason}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => handleRemoveItem(flaggedItem)}
                >
                  Remove Image
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => modResolveFlaggedImage(flaggedItem)}
                >
                  Resolve Image as Safe
                </Button>
              </Grid>
              <Dialog
                open={removeItemPanelOpen}
                onClose={() => setRemoveItemPanelOpen(false)}
              >
                <DialogTitle>Image Removal</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {flaggedItem.owner.email === currentUser.email
                      ? `By removing this image, we are asserting that the content violates the code of conduct for our institution.`
                      : `Please provide details concerning why this image is being removed from the gallery:`}
                  </DialogContentText>
                  {flaggedItem.owner.email !== currentUser.email && (
                    <TextField
                      error={removalDetailsError.length !== 0}
                      helperText={removalDetailsError}
                      value={removalDetails}
                      autoFocus
                      multiline
                      onChange={(e) => setRemovalDetails(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (removalDetails.length === 0) {
                            setRemovalDetailsError(
                              "We need some details here before removing an image from the gallery..."
                            );
                          } else {
                            stageImageForRemoval({
                              ...flaggedItem,
                              reason: removalDetails,
                            });
                            setRemovalDetails("");
                            setRemovalDetailsError("");
                            setRemoveItemPanelOpen(false);
                          }
                        }
                      }}
                      margin="dense"
                      id="reason"
                      label="Removal Details"
                      type="text"
                      fullWidth
                      variant="standard"
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setRemovalDetails("");
                      setRemovalDetailsError("");
                      setRemoveItemPanelOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      if (removalDetails.length === 0) {
                        setRemovalDetailsError(
                          "We need some details here before removing an image from the gallery..."
                        );
                      } else {
                        stageImageForRemoval({
                          ...flaggedItem,
                          reason: removalDetails,
                        });
                        setRemovalDetails("");
                        setRemovalDetailsError("");
                        setRemoveItemPanelOpen(false);
                      }
                    }}
                  >
                    Remove Image
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default ModeratorPanelItem;
