import React from "react";
import { styled } from "@mui/material/styles";
import { Container, Grid, Typography, Link } from "@mui/material";
import gwdLabIcon from "../assets/gwdLabIcon.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      <Link color="inherit" href="https://www.aurora-schools.org" target="new">
        GreenWorks Design Lab
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const FooterContainer = styled(Container)(({ theme }) => ({
  marginTop: "10em",
  marginBottom: "5em",
}));

export default function Footer() {
  //const classes = useStyles();

  return (
    <footer>
      <FooterContainer>
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <img
              src={gwdLabIcon}
              alt="GreenWorks Design Lab Logo"
              style={{ height: "100px" }}
            />
          </Grid>
          <Grid item>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="body1">
                  Designed in the Aurora City Schools
                </Typography>
              </Grid>
              <Typography variant="body2">Located in Aurora, Ohio</Typography>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FooterContainer>
    </footer>
  );
}
