import { useState, useEffect } from "react";
import { firestore } from "../firebaseInit";
import {
  query,
  collection,
  where,
  orderBy,
  limit,
  onSnapshot,
  startAfter,
  startAt,
  endAt,
  getDocs,
} from "firebase/firestore";

// global variable for the unsub processes
let paginationUnsub = null;
let cacheUnsub = null;

const useGalleryWall = () => {
  const [loading, setLoading] = useState(true);
  const [imageCache, setImageCache] = useState([]);
  const LIMIT = 30;
  const [fetchedImages, setFetchedImages] = useState([]);
  const [lastDocInSet, setLastDocInSet] = useState(null);
  const [paginationSeries, setPaginationSeries] = useState([]);
  const [paginationPosition, setPaginationPosition] = useState(0);
  const [endIsReached, setEndIsReached] = useState(false);
  const [endPosition, setEndPosition] = useState(null);
  const [previousIsPossible, setPreviousIsPossible] = useState(false);

  // establish listener for image cache, which will fetch all
  // added images after the effect becomes active
  useEffect(() => {
    // establish properties of the snapshot listener
    const collectionRef = collection(firestore, "images");

    // need the current timestamp
    const currentTime = new Date();

    const q = query(
      collectionRef,
      where("isCensored", "==", false),
      where("createdOn", ">", currentTime)
    );

    // load added images into the imageCache
    cacheUnsub = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          setImageCache((prevState) => [
            ...prevState,
            { id: change.doc.id, ...change.doc.data() },
          ]);
        }
      });
    });

    // clean up the snapshot listener
    return () => {
      if (cacheUnsub) {
        cacheUnsub();
      }

      if (paginationUnsub) {
        paginationUnsub();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGalleryRefresh = () => {
    setFetchedImages([]);
    setImageCache([]);
    setPaginationSeries([]);
    setLastDocInSet(null);
    setPaginationPosition(0);
    setEndIsReached(false);
    setEndPosition(null);
    setPreviousIsPossible(false);
    fetchInitialImageSet();
  };

  const establishTargetedQuery = (firstDoc = null, lastDoc = null) => {
    if (!firstDoc && !lastDoc) {
      // establishing initial query, so...
      return query(
        collection(firestore, "images"),
        where("isCensored", "==", false),
        orderBy("createdOn", "desc"),
        limit(LIMIT)
      );
    } else if (!firstDoc && lastDoc) {
      // this will be a targeted query in the forward direction, so...
      return query(
        collection(firestore, "images"),
        where("isCensored", "==", false),
        orderBy("createdOn", "desc"),
        startAfter(lastDoc),
        limit(LIMIT)
      );
    } else if (firstDoc && lastDoc) {
      // this will a targeted query for the previous set
      return query(
        collection(firestore, "images"),
        where("isCensored", "==", false),
        orderBy("createdOn", "desc"),
        startAt(firstDoc),
        endAt(lastDoc)
      );
    }
  };

  // this establishes the listener for the pagination strategy
  const setupListener = async (queryToUse) => {
    // clear existing listeners if needed
    if (paginationUnsub) {
      paginationUnsub();
    }

    // establish snap listener
    paginationUnsub = onSnapshot(queryToUse, (querySnapshot) => {
      const loadedImages = [];
      querySnapshot.forEach((doc) => {
        loadedImages.push({ id: doc.id, ...doc.data() });
      });

      setFetchedImages(loadedImages);
      setLoading(false);
    });
  };

  const fetchInitialImageSet = async () => {
    setLoading(true);

    // initialize pagination
    setPaginationPosition(0);
    setPaginationSeries([]);

    // Query the first page of docs
    const firstQuery = establishTargetedQuery();

    // we will need actual docs to set pagination set targets
    const imageDocs = [];

    const imageSnapshots = await getDocs(firstQuery);

    if (imageSnapshots.size === 0) {
      return setLoading(false);
    }

    imageSnapshots.forEach((doc) => {
      imageDocs.push({ id: doc.id, ...doc.data() });
    });

    const firstDoc = imageSnapshots.docs[0];
    const lastDoc = imageSnapshots.docs[imageSnapshots.docs.length - 1];

    // we may need to disable the next button if the snapshot
    // size is less than or equal to the limit
    if (imageSnapshots.size < LIMIT) {
      setEndIsReached(true);
    }

    setLastDocInSet(lastDoc);

    setPaginationSeries((prevState) => [...prevState, { firstDoc, lastDoc }]);

    // since we do not want new images to interfere with
    // the pagination order, we modify the initial query
    // to start with a specific doc that will remain static
    // even after images are added

    const initialQuery = query(
      collection(firestore, "images"),
      where("isCensored", "==", false),
      orderBy("createdOn", "desc"),
      startAt(firstDoc),
      endAt(lastDoc)
    );

    await setupListener(initialQuery);
  };

  const loadPreviousSet = async () => {
    let queryTargets = null;

    // check to see if we have to make load more available
    if (endIsReached && paginationSeries.length - paginationPosition >= 1) {
      setEndIsReached(false);
    }

    if (paginationPosition >= 1) {
      queryTargets = paginationSeries[paginationPosition - 1];
    }

    if (paginationPosition === 1) {
      setPreviousIsPossible(false);
    }

    // Construct a new query for the previous set
    const prev = establishTargetedQuery(
      queryTargets.firstDoc,
      queryTargets.lastDoc
    );

    setLastDocInSet(queryTargets.lastDoc);

    setPaginationPosition(paginationPosition - 1);

    await setupListener(prev);
  };

  // fetches the next set of images for pagination
  const fetchMoreImages = async () => {
    try {
      // select method for loading more according to current pagination state
      if (!endIsReached && paginationSeries.length - paginationPosition <= 1) {
        // find new targets and add them to the series
        // construct a new query starting after lastDocInSet
        const next = establishTargetedQuery(null, lastDocInSet);

        const imageDocs = [];

        const imageSnapshots = await getDocs(next);

        if (imageSnapshots.empty) {
          setEndPosition(paginationPosition);
          return setEndIsReached(true);
        }

        imageSnapshots.forEach((doc) => {
          imageDocs.push({ id: doc.id, ...doc.data() });
        });

        const firstDoc = imageSnapshots.docs[0];
        const lastDoc = imageSnapshots.docs[imageSnapshots.docs.length - 1];

        setLastDocInSet(lastDoc);

        // check if we actually need to add the pagination targets
        if (paginationSeries.length - paginationPosition <= 1) {
          // we need to add the targets
          setPaginationSeries((prevState) => [
            ...prevState,
            { firstDoc, lastDoc },
          ]);
        }

        setPaginationPosition(paginationPosition + 1);

        await setupListener(next);

        if (imageSnapshots.size < LIMIT) {
          setEndIsReached(true);
          setEndPosition(paginationPosition);
        }

        return setPreviousIsPossible(true);
      } else if (!endIsReached) {
        // using existing targets to load next set
        if (paginationPosition === endPosition) {
          setEndIsReached(true);
        }

        // use existing targets to load further sets
        let queryTargets = null;

        if (paginationPosition >= 1) {
          queryTargets = paginationSeries[paginationPosition + 1];
        }

        // if we are back to the start of the series, we need to load
        // the next set of targets, so...
        if (paginationPosition === 0) {
          // be sure our previous button is available
          if (!previousIsPossible) {
            setPreviousIsPossible(true);
          }

          queryTargets = paginationSeries[1];
        }

        // Construct a new query for the next set
        const next = establishTargetedQuery(
          queryTargets.firstDoc,
          queryTargets.lastDoc
        );

        setLastDocInSet(queryTargets.lastDoc);

        setPaginationPosition(paginationPosition + 1);

        await setupListener(next);
      }
    } catch (err) {
      console.log(err);
      setEndIsReached(true);
    }
  };

  return {
    loading,
    setLoading,
    fetchedImages,
    fetchInitialImageSet,
    fetchMoreImages,
    endIsReached,
    loadPreviousSet,
    previousIsPossible,
    imageCache,
    handleGalleryRefresh,
  };
};

export default useGalleryWall;
