import { useState } from "react";
import {
  Grid,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import useImageDetail from "../hooks/useImageDetail";
import { useAuth } from "../contexts/AuthContext";

const FlagContentDialog = ({
  image,
  comment = null,
  flagOpen,
  handleFlagClose,
}) => {
  const { currentUser } = useAuth();
  const { handleFlagContent } = useImageDetail();
  const [removalDetails, setRemovalDetails] = useState("");
  const [removalDetailsError, setRemovalDetailsError] = useState("");

  const handleFlagSubmit = () => {
    if (removalDetails.length === 0) {
      return setRemovalDetailsError(
        "We need some details here before flagging an item for removal from the gallery..."
      );
    } else {
      handleFlagContent(
        image,
        {
          displayName: currentUser.displayName,
          email: currentUser.email,
          reason: removalDetails,
        },
        comment
      );
      handleFlagClose();
    }
  };

  return (
    <Dialog open={flagOpen}>
      <DialogTitle>Flag Content for Removal</DialogTitle>
      <DialogContent>
        <Grid item container direction="row">
          <Grid item xs={5} sm={4}>
            <img
              src={`${image.imageLink}`}
              alt={image.description}
              width="150px"
            />
          </Grid>
          <Grid item xs={7} sm={8}>
            <DialogContentText>
              Please provide details concerning why this item should be removed
              from the gallery. This action will remove the item from the
              gallery until it is reviewed by a moderator:
            </DialogContentText>
          </Grid>
        </Grid>
        <TextField
          error={removalDetailsError.length !== 0}
          helperText={removalDetailsError}
          autoFocus
          multiline
          onChange={(e) => setRemovalDetails(e.target.value)}
          margin="dense"
          id="reason"
          label="Flag Details"
          type="text"
          fullWidth
          variant="standard"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleFlagSubmit();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setRemovalDetails("");
            setRemovalDetailsError("");
            handleFlagClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleFlagSubmit}>
          Flag Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlagContentDialog;
