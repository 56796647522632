import _ from "lodash";
import { useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Fab,
  Card,
  CardMedia,
  CardContent,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useAddImage from "../hooks/useAddImage";

const AddImageDialog = ({ addImageOpen, handleAddImageClose }) => {
  const { addImage } = useAddImage();
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [tagsText, setTagsText] = useState("");
  const [tagFieldError, setTagFieldError] = useState("");
  const [tags, setTags] = useState([]);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const resetUploadUI = () => {
    setTagsText("");
    setTagFieldError("");
    setTags([]);
    setDescription("");
    setImage(null);
  };

  const handleTagDelete = (tagToDelete) => {
    console.log("POP!");
    // find and remove the tag from the tags of the image
    let newTags = tags.map((tag) => {
      console.log(tag);
      console.log(tagToDelete);
      if (tag !== tagToDelete) {
        return tag;
      }
      return null;
    });

    newTags = _.compact(newTags);

    setTags(_.compact(newTags));
  };

  const handleAddTags = () => {
    // reset error UI, if required
    if (tagFieldError.length > 0) {
      setTagFieldError("");
    }

    // take comma separated tags and compile them
    // into the image's tags array
    const rawTags = tagsText.split(",");

    // remove any leading and trailing spaces
    // all tags are lowercase words
    let newTags = rawTags.map((word) => {
      const trimmedWord = word.trim();
      const finalWord = trimmedWord.toLowerCase();
      return finalWord;
    });

    let allTags = [];

    // combine new tags with existing tags, if needed
    if (tags.length > 0) {
      allTags = tags.concat(newTags);
    } else {
      allTags = newTags;
    }

    // be sure we remove any duplicate tags in the array, as well as empty strings
    const cleansedTags = _.uniq(_.compact(allTags));

    setTagsText([]);
    setTags(cleansedTags);
  };

  const handleTagsChange = (e) => {
    setTagsText(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // let's make sure they are uploading an actual image file
    if (!image.type.includes("image")) {
      setTagFieldError(
        "You can only post verifiable image files to the Gallery..."
      );
      return;
    }

    if (image && description && tagsText.length > 0) {
      setTagFieldError("Please add your tags before submitting...");
      return;
    }

    if (image && description) {
      addImage({ image, description, tags });
      resetUploadUI();
      handleAddImageClose();
    }
  };

  return (
    <Dialog open={addImageOpen} maxWidth="xl">
      <DialogContent>
        <Grid
          item
          container
          style={{
            marginTop: "1em",
          }}
          direction="column"
        >
          <Grid item align="center" sx={{ marginBottom: "1em" }}>
            <Typography variant="h4">{`Post New Image`}</Typography>
          </Grid>
          <Grid item align="center" sx={{ marginBottom: "3em" }}>
            <Typography variant="body2">
              Thank you for contributing to the richness of our gallery!
            </Typography>
          </Grid>
          <Grid item container direction="column" alignItems="center">
            {image && (
              <Grid item sx={{ marginBottom: "1em" }}>
                <Card sx={{ maxWidth: 400 }}>
                  <CardMedia
                    component="img"
                    image={URL.createObjectURL(image)}
                    alt="image preview..."
                  />
                  <CardContent>
                    <Grid item container direction="column">
                      <Grid item>
                        <Typography noWrap variant="body2" component="div">
                          Image Upload Preview
                        </Typography>
                      </Grid>
                      {tags.length > 0 && (
                        <Grid
                          item
                          container
                          direction="row"
                          spacing={1}
                          sx={{ marginTop: "1em" }}
                        >
                          {tags.map((tag) => (
                            <Grid key={tag} item>
                              <Chip
                                label={tag}
                                variant="outlined"
                                color="secondary"
                                onDelete={() => handleTagDelete(tag)}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {!image ? (
              <Grid item>
                <label htmlFor="upload-image">
                  <input
                    style={{ display: "none" }}
                    id="upload-image"
                    name="upload-image"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />

                  <Fab
                    color="primary"
                    size="small"
                    component="span"
                    aria-label="upload-image-fab"
                    variant="extended"
                  >
                    <AddIcon />{" "}
                    <Typography variant="body2">Upload Image</Typography>
                  </Fab>
                </label>
              </Grid>
            ) : (
              <Button variant="outlined" onClick={resetUploadUI}>
                Cancel Upload
              </Button>
            )}
            {image && (
              <>
                <Grid
                  item
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: 400,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    id="description"
                    label="Description"
                    name="description"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: 400,
                    maxWidth: "100%",
                  }}
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      error={tagFieldError.length > 0 ? true : false}
                      id="tags"
                      label="Tags (separate tags with commas)"
                      name="tags"
                      value={tagsText}
                      onChange={handleTagsChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && tagsText.length > 0) {
                          handleAddTags();
                        }
                      }}
                      helperText={tagFieldError.length > 0 && tagFieldError}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      disabled={!tagsText.length > 0}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={handleAddTags}
                    >
                      Add Tags
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              item
              sx={{
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              <Button
                disabled={!description || !description.length > 0 || !image}
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
              >
                Post
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                marginTop: "5px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  resetUploadUI();
                  handleAddImageClose();
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddImageDialog;
