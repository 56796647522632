import React, { useContext } from "react";
import { Container, Grid, Button, Typography, Divider } from "@mui/material";
import useCollection from "../hooks/useCollection";
import ImageSetDisplayPanel from "./ImageSetDisplayPanel";
import { useHistory } from "react-router-dom";
import { NavContext } from "../contexts/NavContext";
import CloseCollectionIcon from "@mui/icons-material/CancelTwoTone";

const Collection = () => {
  const { collectedImages } = useCollection();
  const { setNavState } = useContext(NavContext);
  const history = useHistory();

  const handleCollectionClose = () => {
    setNavState({ collectionActive: false });
    history.push("/");
  };

  return (
    <Container fixed>
      <Grid item container direction="column">
        <Grid item align="right" sx={{ marginTop: "10px" }}>
          <CloseCollectionIcon
            onClick={handleCollectionClose}
            sx={{ fontSize: "40px", cursor: "pointer" }}
          />
        </Grid>
        <Grid item align="center">
          <Typography gutterBottom variant="h2">
            Your Image Collection
          </Typography>
        </Grid>
        <Divider
          sx={{ marginTop: "30px", marginBottom: "25px" }}
          variant="middle"
        />
        {collectedImages.length > 0 ? (
          <ImageSetDisplayPanel imageSet={collectedImages} />
        ) : (
          <Typography variant="h5" align="center">
            You have not collected any images yet.
          </Typography>
        )}
      </Grid>

      <Grid item align="center" sx={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleCollectionClose}
        >
          Close Collection
        </Button>
      </Grid>
    </Container>
  );
};

export default Collection;
