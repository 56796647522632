import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../firebaseInit";
import { doc, onSnapshot } from "firebase/firestore";
import ImageDetails from "../components/ImageDetails";

const withImageDetails = (BaseComponent) => (props) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentImageId, setCurrentImageId] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);

  const imageDocId = useParams().id.slice(1);

  // capture current image Id from URL
  useEffect(() => {
    setCurrentImageId(imageDocId);
  }, [imageDocId]);

  // establish listener to the current image doc
  useEffect(() => {
    try {
      const unsub = onSnapshot(
        doc(firestore, "images", currentImageId),
        (doc) => {
          setCurrentImage({ id: doc.id, ...doc.data() });
          setLoading(false);
        }
      );

      return () => {
        unsub();
      };
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  }, [currentImageId]);

  return (
    <BaseComponent
      {...props}
      currentImage={currentImage}
      error={error}
      loading={loading}
    />
  );
};

const ImageDetailsContainer = withImageDetails(ImageDetails);

export default ImageDetailsContainer;
