import {
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import FlagContent from "./FlagContent";
import React, { useEffect, useState } from "react";
import formatDate from "../utils/dateFormatter";
import useImageDetail from "../hooks/useImageDetail";
import { useAuth } from "../contexts/AuthContext";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const CommentPanel = ({ currentImage }) => {
  const { handleAddComment } = useImageDetail();
  const { handleRemoveComment } = useImageDetail();
  const [orderedComments, setOrderedComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const { currentUser } = useAuth();

  // order the comments such that most recent comes first
  useEffect(() => {
    setOrderedComments(
      _.orderBy(currentImage.comments, ["createdOn"], ["desc"])
    );
  }, [currentImage.comments]);

  return (
    <Grid item container direction="column">
      <Grid item container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={10}>
          <TextField
            required
            sx={{ display: "flex", flexGrow: 1 }}
            multiline
            id="image-comment"
            name="image-comment"
            label="Comment"
            variant="outlined"
            value={newComment}
            onChange={(e) => {
              setNewComment(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && newComment.length > 0) {
                e.preventDefault();
                handleAddComment(currentImage.id, newComment);
                setNewComment("");
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            disabled={!newComment || !newComment.length > 0}
            variant="contained"
            onClick={(e) => {
              handleAddComment(currentImage.id, newComment);
              setNewComment("");
            }}
          >
            Post
          </Button>
        </Grid>
      </Grid>
      <Grid item container direction="column" sx={{ marginTop: "5px" }}>
        <Grid item>
          {orderedComments.length > 0 ? (
            <List>
              {orderedComments.map((comment) =>
                !comment.isCensored ? (
                  <ListItem key={comment.createdOn}>
                    <ListItemText>
                      <Grid item container direction="row">
                        <Grid item container direction="column" xs={8}>
                          <Grid item>
                            <Typography>{comment.text}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              {`${comment.owner.displayName} on ${formatDate(
                                comment.createdOn
                              )}`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          direction="row"
                          xs={4}
                          spacing={1}
                          justifyContent="flex-end"
                        >
                          <Grid item>
                            {comment.owner.email === currentUser.email ? (
                              <Tooltip
                                title="Delete your comment"
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => {
                                    handleRemoveComment(comment);
                                  }}
                                  aria-label="delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : !comment.isResolved ? (
                              <FlagContent
                                currentImage={currentImage}
                                comment={comment}
                              />
                            ) : (
                              <Tooltip
                                title="Comment approved by moderator"
                                placement="top"
                              >
                                <IconButton aria-label="delete">
                                  <VerifiedUserIcon sx={{ color: "blue" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                ) : null
              )}
            </List>
          ) : (
            <Typography>
              There are currently no comments associated to this image.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommentPanel;
