import { useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { ImageTransactionContext } from "../contexts/ImageTransactionContext";

const DeleteImageDialog = ({ image, destroyOpen, handleDestroyClose }) => {
  const { destroyImage } = useContext(ImageTransactionContext);

  return (
    <Dialog open={destroyOpen}>
      <DialogTitle>Delete Image</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Removing this image will remove all comments made on this image. Others who have added your image to their collection will also lose acccess to this image.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            handleDestroyClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            destroyImage(image);
            handleDestroyClose();
          }}
        >
          Delete Image
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteImageDialog;
