import { useState, useEffect } from "react";
import _ from "lodash";
import { firestore } from "../firebaseInit";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import EvidencePanel from "../components/EvidencePanel";

const withEvidenceItems = (BaseComponent) => (props) => {
  const [loading, setLoading] = useState(true);
  const [removalCache, setRemovalCache] = useState([]);
  const [evidenceItemsCache, setEvidenceItemsCache] = useState([]);
  const [evidenceItems, setEvidenceItems] = useState([]);

  const mergeEvidenceItems = () => {
    let newEvidenceItems = [...evidenceItems];

    evidenceItemsCache.map((evidenceItem) => {
      newEvidenceItems.push(evidenceItem);
      return null;
    });

    setEvidenceItems(_.orderBy(newEvidenceItems, ["createdOn"], ["asc"]));
    setEvidenceItemsCache([]);
  };

  // establish listener for flagged images
  useEffect(() => {
    setLoading(true);

    // establish properties of the snapshot listener
    const collectionRef = collection(firestore, "flaggingIncidents");

    const q = query(
      collectionRef,
      where("isResolved", "==", true),
      where("isCensored", "==", true),
      orderBy("createdOn", "asc")
    );

    // load added images into the newEvidenceItems
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          setEvidenceItemsCache((prevState) => [
            ...prevState,
            { id: change.doc.id, type: "image", ...change.doc.data() },
          ]);
        }

        // Modifications are of no concern at this stage...

        if (change.type === "removed") {
          setRemovalCache((prevState) => [
            ...prevState,
            { id: change.doc.id, ...change.doc.data() },
          ]);
        }
      });

      setLoading(false);
    });

    // clean up the snapshot listener
    return () => {
      unsubscribe();
    };
  }, []);

  // establish listener for flagged comments
  useEffect(() => {}, []);

  useEffect(() => {
    if (removalCache.length > 0) {
      removalCache.map((removedItem) => {
        setEvidenceItems(
          evidenceItems.filter((item) => item.id !== removedItem.id)
        );
        setEvidenceItemsCache(
          evidenceItemsCache.filter((item) => item.id !== removedItem.id)
        );
        return null;
      });

      setRemovalCache([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removalCache.length]);

  return (
    <BaseComponent
      {...props}
      evidenceItems={evidenceItems}
      evidenceItemsCache={evidenceItemsCache}
      mergeEvidenceItems={mergeEvidenceItems}
      loading={loading}
    />
  );
};

const EvidencePanelContainer = withEvidenceItems(EvidencePanel);

export default EvidencePanelContainer;
