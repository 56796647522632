import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Container, Typography, Button, Divider } from "@mui/material";
import { NavContext } from "../contexts/NavContext";
import CloseEvidencePanelIcon from "@mui/icons-material/CancelTwoTone";
import EvidencePanelItem from "./EvidencePanelItem";

const EvidencePanel = ({
  evidenceItems,
  evidenceItemsCache,
  loading,
  mergeEvidenceItems,
}) => {
  const { setNavState } = useContext(NavContext);
  const history = useHistory();

  useEffect(() => {
    if (
      !loading &&
      evidenceItemsCache &&
      evidenceItemsCache.length &&
      evidenceItemsCache.length > 0
    ) {
      mergeEvidenceItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleEvidencePanelClose = () => {
    setNavState({ accessPanelIsOpen: false });
    setNavState({ evidencePanelIsOpen: false });
    setNavState({ modPanelIsOpen: false });
    history.push("/");
  };

  return (
    <Container>
      <Grid item container direction="column">
        <Grid item align="right" sx={{ marginTop: "10px" }}>
          <CloseEvidencePanelIcon
            onClick={handleEvidencePanelClose}
            sx={{ fontSize: "40px", cursor: "pointer" }}
          />
        </Grid>
        <Grid item align="center">
          <Typography gutterBottom variant="h2">
            Evidence Panel
          </Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="body2" sx={{ fontSize: "20px" }}>
            The evidence panel is reserved for restricted images and comments
            that moderators have marked for permanent removal.
          </Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="body2" sx={{ fontSize: "20px" }}>
            We temporarily retain these files for administrative / law
            enforcement review prior to permanent disposal, if required. Do not,
            in any way, replicate nor distribute the images or comments found
            here without guidance from law enforcement.
          </Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="body2" sx={{ fontSize: "20px" }}>
            Please practice extreme caution when deleting these items.
          </Typography>
        </Grid>
        <Divider
          sx={{ marginTop: "10px", marginBottom: "10px" }}
          variant="middle"
        />
        {evidenceItemsCache &&
        evidenceItemsCache.length &&
        evidenceItemsCache.length > 0 ? (
          <Grid item align="center">
            <Button
              size="large"
              variant="outlined"
              onClick={mergeEvidenceItems}
            >
              {evidenceItemsCache.length === 1
                ? `1 new item...`
                : `${evidenceItemsCache.length} new items...`}
            </Button>
          </Grid>
        ) : null}
        {evidenceItems && evidenceItems.length && evidenceItems.length > 0 ? (
          evidenceItems.map((evidenceItem) => (
            <EvidencePanelItem
              key={evidenceItem.id}
              evidenceItem={evidenceItem}
            />
          ))
        ) : (
          <Grid item sx={{ marginTop: "30px" }} align="center">
            <Typography variant="body1" sx={{ fontSize: "18px" }}>
              There are no evidence items at the moment...
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item align="center" sx={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleEvidencePanelClose}
        >
          Close Evidence Panel
        </Button>
      </Grid>
    </Container>
  );
};

export default EvidencePanel;
