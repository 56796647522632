import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import useCollection from "../hooks/useCollection";

const CollectionRemovalDialog = ({
  image,
  removeOpen,
  handleRemoveModalClose,
}) => {
  const { removeImageFromCollection } = useCollection();

  return (
    <Dialog open={removeOpen} onClose={handleRemoveModalClose}>
      <DialogTitle>Removing from Collection</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action will remove this image from your collection.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            handleRemoveModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            removeImageFromCollection(image);
          }}
        >
          Remove from Collection
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollectionRemovalDialog;
