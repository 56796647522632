import { Grid, Typography } from "@mui/material";

const AboutPage = () => {
  return (
    <Grid item container direction='column' sx={{ marginTop: "7em" }}>
      <Grid item align='center'>
        <Typography sx={{ fontSize: "70px" }}>
          {`Future about page...`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutPage;
