import {
  Typography,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Tooltip,
  Grid,
} from "@mui/material";
import FlagContent from "./FlagContent";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const ImageDetail = ({ currentImage }) => {
  return (
    <Card sx={{ maxWidth: 700 }}>
      <CardMedia
        component="img"
        image={currentImage.imageLink}
        alt={currentImage.description}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {currentImage.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container direction="row" justifyContent="flex-end">
          {!currentImage.isResolved ? (
            <FlagContent currentImage={currentImage} />
          ) : (
            <Tooltip
              title="Image has been approved by moderator"
              placement="top"
            >
              <VerifiedUserIcon sx={{ color: "blue" }} />
            </Tooltip>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
};

export default ImageDetail;
