import { useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Typography,
  Container,
} from "@mui/material";
import useRandomImage from "../hooks/useRandomImage";
import ImageItem from "./ImageItem";

const RandomizerDialog = ({ randomOpen, handleRandomClose }) => {
  const { setupImageListener, unloadListener, image } = useRandomImage();

  useEffect(() => {
    if (randomOpen) {
      setupImageListener();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomOpen]);

  return (
    <Dialog open={randomOpen} maxWidth="xl">
      <DialogContent>
        <Grid item container direction="column">
          <Grid item align="center" sx={{ marginBottom: "1em" }}>
            <Typography variant="h4">{`Randomizer`}</Typography>
          </Grid>
          <Grid item align="center" sx={{ marginBottom: "3em" }}>
            <Typography variant="body2">
              Fetching random images from the Gallery...
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  unloadListener();
                  setupImageListener();
                }}
              >
                Fetch Another Image
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  unloadListener();
                  handleRandomClose();
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
          <Grid item align="center" sx={{ marginBottom: "2em" }}></Grid>
          {image && (
            <Container>
              <Grid item container direction="column">
                <Grid item align="center">
                  <ImageItem image={image} isSingular={true} />
                </Grid>
              </Grid>
            </Container>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RandomizerDialog;
