import React, { useContext, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import formatDate from "../utils/dateFormatter";
import { ImageTransactionContext } from "../contexts/ImageTransactionContext";
import { CommentTransactionContext } from "../contexts/CommentTransactionContext";

const EvidencePanelItem = ({ evidenceItem }) => {
  const { destroyImage } = useContext(ImageTransactionContext);
  const { destroyComment } = useContext(CommentTransactionContext);

  const [destroyImagePanelOpen, setDestroyImagePanelOpen] = useState(false);

  const handleDestroyItem = () => {
    setDestroyImagePanelOpen(true);
  };

  return (
    <Paper
      key={evidenceItem.id}
      elevation={2}
      sx={{ marginTop: "5px", marginBottom: "5px", padding: "10px" }}
    >
      <Grid item container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={3}>
          <img
            src={`${evidenceItem.imageLink}`}
            alt={evidenceItem.description}
            width="200px"
          />
        </Grid>
        <Grid item xs={6}>
          <Grid item container direction="column">
            {evidenceItem.type === "comment" ? (
              <Grid item>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>
                  {`${evidenceItem.owner.displayName} (${
                    evidenceItem.owner.email
                  }) posted this comment to the Gallery on ${formatDate(
                    evidenceItem.createdOn
                  )}`}
                </Typography>
                <Grid item>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    {`Comment: ${evidenceItem.comment}`}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>{`${
                  evidenceItem.owner.displayName
                } (${
                  evidenceItem.owner.email
                }) posted this image to the Gallery on ${formatDate(
                  evidenceItem.createdOn
                )}`}</Typography>
              </Grid>
            )}
            <Grid item container direction="column" sx={{ marginTop: "5px" }}>
              <Grid item>
                <Typography sx={{ fontSize: "13px" }}>{`Flagged by ${
                  evidenceItem.flaggerDisplayName
                }, on ${formatDate(evidenceItem.createdOn)}`}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ fontSize: "15px", fontWeight: "bold" }}
                >{`Reason: ${evidenceItem.reason}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Button variant="contained" onClick={handleDestroyItem}>
                Destroy Item
              </Button>
            </Grid>
            <Dialog open={destroyImagePanelOpen}>
              <DialogTitle>Item Destruction</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  This item will be permantely destroyed and no longer
                  accessible to any other system-wide administrator once this
                  action is complete.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setDestroyImagePanelOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (evidenceItem.type === "comment") {
                      destroyComment(evidenceItem);
                    } else {
                      destroyImage(evidenceItem);
                    }
                    setDestroyImagePanelOpen(false);
                  }}
                >
                  Destroy Item
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EvidencePanelItem;
