import { useContext } from "react";
import { ImageTransactionContext } from "../contexts/ImageTransactionContext";

const useAddImage = () => {
  const { addNewImage } = useContext(ImageTransactionContext);

  const addImage = (newImageData) => {
    addNewImage(newImageData);
  };

  return {
    addImage,
  };
};

export default useAddImage;
