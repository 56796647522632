import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Grid, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { NavContext } from "../contexts/NavContext";
import { ImageTransactionContext } from "../contexts/ImageTransactionContext";
import RandomizerDialog from "./RandomizerDialog";
import AddImageDialog from "./AddImageDialog";

const OperationsPanel = () => {
  const { navState, setNavState } = useContext(NavContext);
  const { fetchedSingleImage } = useContext(ImageTransactionContext);
  const history = useHistory();

  const [collectionHasImage, setCollectionHasImage] = useState(false);
  const [randomOpen, setRandomOpen] = useState(false);
  const [addImageOpen, setAddImageOpen] = useState(false);

  const handleRandomClose = () => {
    setRandomOpen(false);
  };

  const checkForImage = async () => {
    setCollectionHasImage(await fetchedSingleImage());
  };

  const handleAddImageOpen = () => {
    setAddImageOpen(true);
  };

  const handleAddImageClose = () => {
    setAddImageOpen(false);
  };

  // be sure we have at least an image in the collection before we present
  // sort and randomizer options
  useEffect(() => {
    checkForImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle navigation context changes for proper UI flow
  useEffect(() => {
    if (
      navState.randomizerOn === true &&
      navState.imageDetailsActive === false
    ) {
      setNavState({ imageDetailsActive: true });
    }
    if (
      navState.randomizerOn === false &&
      navState.imageDetailsActive === true
    ) {
      setNavState({ imageDetailsActive: false });
      history.push("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navState.randomizerOn]);

  const handleRandomizerOpen = () => {
    setRandomOpen(true);
  };

  return (
    <>
      <Grid item container direction="column">
        <Grid
          sx={{ marginTop: "120px" }}
          item
          container
          direction="row"
          justifyContent="flex-end"
        >
          {navState.modPanelIsOpen === false &&
            navState.evidencePanelIsOpen === false &&
            navState.accessPanelIsOpen === false &&
            navState.randomizerOn === false &&
            navState.imageDetailsActive === false &&
            navState.addImageActive === false &&
            navState.collectionActive === false &&
            navState.searchActive === false &&
            collectionHasImage && (
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ marginRight: "10px" }}
                  size="small"
                  startIcon={<ImageSearchIcon />}
                  onClick={() => {
                    setNavState({ searchActive: true });
                    history.push(`/search`);
                  }}
                >
                  Image Search
                </Button>
              </Grid>
            )}
          <Grid item>
            <Button
              variant="outlined"
              sx={{ marginRight: "10px" }}
              size="small"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                handleAddImageOpen();
              }}
            >
              Add Image
            </Button>
          </Grid>

          {navState.modPanelIsOpen === false &&
            navState.evidencePanelIsOpen === false &&
            navState.accessPanelIsOpen === false &&
            navState.imageDetailsActive === false &&
            navState.addImageActive === false &&
            navState.collectionActive === false &&
            navState.searchActive === false &&
            collectionHasImage && (
              <Grid item>
                <Button
                  sx={{ marginRight: "10px" }}
                  variant={navState.randomizerOn ? "contained" : "outlined"}
                  size="small"
                  startIcon={<ShuffleIcon />}
                  onClick={() => {
                    handleRandomizerOpen();
                  }}
                >
                  Randomizer
                </Button>
              </Grid>
            )}
        </Grid>
        {navState.modPanelIsOpen === false &&
          navState.evidencePanelIsOpen === false &&
          navState.accessPanelIsOpen === false && (
            <Grid
              item
              container
              direction="row"
              sx={{ marginBottom: "15px", marginTop: "5px" }}
              justifyContent="flex-end"
            >
              <Typography
                variant="body2"
                sx={{ marginRight: "30px", fontStyle: "italic" }}
              >
                {navState.randomizerOn
                  ? "Showing randomly selected image..."
                  : navState.imageDetailsActive
                  ? "Showing selected image details..."
                  : navState.addImageActive
                  ? "Adding an image to the gallery..."
                  : navState.collectionActive
                  ? "Showing personal image collection..."
                  : navState.searchActive
                  ? "Searching for images..."
                  : navState.sortStrategy === "latest"
                  ? "Showing most recently uploaded images..."
                  : "Showing images with the most comments..."}
              </Typography>
            </Grid>
          )}
      </Grid>
      <RandomizerDialog
        randomOpen={randomOpen}
        handleRandomClose={handleRandomClose}
      />
      <AddImageDialog
        addImageOpen={addImageOpen}
        handleAddImageClose={handleAddImageClose}
      />
    </>
  );
};

export default OperationsPanel;
