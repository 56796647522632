import React, { useState, createContext } from "react";

export const LoadingContext = createContext();

export const LoadingProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreementOpen, setIsAgreementOpen] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isAgreementOpen,
        setIsAgreementOpen,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
};
