import { useState, useEffect, useContext } from "react";
import { firestore } from "../firebaseInit";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { LoadingContext } from "../contexts/LoadingContext";

// global varaible for listener
let unsub = null;

const useImageSearch = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const [foundImages, setFoundImages] = useState([]);
  const [searchResult, setSearchResult] = useState("");

  const establishSearchListener = (searchTerm) => {
    console.log("Boom: ", searchTerm);
    setIsLoading(true);
    const q = query(
      collection(firestore, "images"),
      where("tags", "array-contains", searchTerm),
      where("isFlagged", "==", false),
      where("isCensored", "==", false)
    );

    unsub = onSnapshot(q, (querySnapshot) => {
      const fetchedImages = [];
      querySnapshot.forEach((doc) => {
        fetchedImages.push({ id: doc.id, ...doc.data() });
      });

      setFoundImages(fetchedImages);

      setIsLoading(false);

      setSearchResult(
        querySnapshot.size === 1
          ? `${querySnapshot.size} image was found...`
          : querySnapshot.size === 0
          ? `No images were found...`
          : `${querySnapshot.size} images were found...`
      );
    });
  };

  // establish listener for found images
  const findImages = (searchTerm) => {
    establishSearchListener(searchTerm);
  };

  // be sure search listener is collapsed upon unmount
  useEffect(() => {
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, []);

  return {
    foundImages,
    findImages,
    searchResult,
  };
};

export default useImageSearch;
