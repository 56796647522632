import React from "react";
import { ImageList } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImageItem from "./ImageItem";

const ImageSetDisplayPanel = ({ imageSet }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ImageList
      variant="woven"
      cols={isSmall ? 1 : isMedium ? 2 : 3}
      gap={8}
      sx={{ padding: "40px" }}
    >
      {imageSet.map((image) => (
        <ImageItem image={image} key={image.id} />
      ))}
    </ImageList>
  );
};

export default ImageSetDisplayPanel;
