import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import MessagingPanel from "./MessagingPanel";
import GalleryNavbar from "./GalleryNavbar";
import LandingPage from "./LandingPage";
import AboutPage from "./AboutPage";
import AddImage from "./AddImage";
import ImageDetailsContainer from "../containers/ImageDetailsContainer";
import ModeratorPanelContainer from "../containers/ModeratorPanelContainer";
import EvidencePanelContainer from "../containers/EvidencePanelContainer";
import GalleryWall from "./GalleryWall";
import ProtectedRoute from "./ProtectedRoute";
import UnprotectedRoute from "./UnprotectedRoute";
import OperationsPanel from "./OperationsPanel";
import LoadingDialog from "./LoadingDialog";
import AccessPanel from "./AccessPanel";
import ImageSearch from "./ImageSearch";
import Collection from "./Collection";
import Footer from "./Footer";
import { useAuth } from "../contexts/AuthContext";

const App = () => {
  const { currentUser } = useAuth();

  return (
    <Fragment>
      <CssBaseline />
      <BrowserRouter>
        <GalleryNavbar />
        <MessagingPanel />
        {currentUser && <OperationsPanel />}
        <LoadingDialog />
        <Switch>
          <UnprotectedRoute exact path="/" component={LandingPage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/search" component={ImageSearch} />
          <ProtectedRoute path="/home" component={GalleryWall} />
          <ProtectedRoute path="/add-image" component={AddImage} />
          <ProtectedRoute path="/collection" component={Collection} />
          <ProtectedRoute
            path="/image-details:id"
            component={ImageDetailsContainer}
          />
          <ProtectedRoute
            path="/moderator-panel"
            component={ModeratorPanelContainer}
          />
          <ProtectedRoute
            path="/evidence-panel"
            component={EvidencePanelContainer}
          />
          <ProtectedRoute path="/access-panel" component={AccessPanel} />
        </Switch>
      </BrowserRouter>
      <Footer />
    </Fragment>
  );
};

export default App;
