import { createContext, useContext, useEffect, useState } from "react";
import { doc, setDoc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, firestore } from "../firebaseInit";
import {
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { MessageContext } from "./MessageContext";
import { LoadingContext } from "./LoadingContext";
import { NavContext } from "./NavContext";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

// Google as the provider
const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export default function AuthContextProvider({ children }) {
  const { setMessage } = useContext(MessageContext);
  const { setIsAgreementOpen } = useContext(LoadingContext);
  const { resetNavState } = useContext(NavContext);
  const [currentUser, setCurrentUser] = useState(null);

  const establishAuthedUser = async (authUser) => {
    const docRef = doc(firestore, "users", authUser.email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // check auth claims and inject roles accordingly
      const currentToken = await authUser.getIdTokenResult();
      const isSysAdmin =
        currentToken.claims.isSysAdmin &&
        currentToken.claims.isSysAdmin === true
          ? true
          : false;
      const isModerator =
        currentToken.claims.isModerator &&
        currentToken.claims.isModerator === true
          ? true
          : false;

      // check if this user has been disabled
      if (docSnap.data().isDisabled && docSnap.data().isDisabled === true) {
        // get 'em outta heres...
        setMessage(
          "error",
          "Your account has been disabled by gallery administration. You may want to go talk to your building administrator.",
          7000
        );
        return setCurrentUser(null);
      }

      // check if the user has accepted the reponsible use agreement
      if (
        !docSnap.data().hasAcceptedResponsibleUsePolicy ||
        docSnap.data().hasAcceptedResponsibleUsePolicy === false
      ) {
        setIsAgreementOpen(true);
      }

      return setCurrentUser({
        email: docSnap.id,
        isSysAdmin,
        isModerator,
        ...docSnap.data(),
      });
    } else {
      // doc.data() will be undefined in this case
      setCurrentUser(null);
    }
  };

  const checkSysAdminCustomAuthClaims = async (authUser) => {
    // get array of sysAdmin emails
    const adminsDocRef = doc(firestore, "systemSettings", "adminRoles");
    const adminsDoc = await getDoc(adminsDocRef);
    const sysAdmins = adminsDoc.data().sysAdmins;

    // if authUser is sysAdmin, check for custom auth claim
    if (sysAdmins.includes(authUser.email)) {
      const currentToken = await authUser.getIdTokenResult();

      if (!currentToken.claims.isSysAdmin) {
        const functions = getFunctions();

        const addSysAdminRole = httpsCallable(functions, "addSysAdminRole");

        const result = await addSysAdminRole({ email: authUser.email });

        setMessage(result.data.status, result.data.message, 7000);

        await signOut(auth)
          .then(() => {
            setCurrentUser(null);
          })
          .catch((error) => {
            console.error("There was an error signing out.");
          });
      }
    }
  };

  const emailDomainCheck = (email) => {
    // check that we have a proper org account
    const domain = email.split("@")[1];

    return domain === "aurora-schools.org";
  };

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (authUser) => {
      // fetch user from firebase and set current user to it?
      if (authUser && authUser.email && emailDomainCheck(authUser.email)) {
        // check for and appropriately leverage sysAdmin custom auth claims
        checkSysAdminCustomAuthClaims(authUser);
        establishAuthedUser(authUser);
      }
    });
    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // establish a listener for changes in the isDisabled state of the currentUser
  // or changes in the collectedIds
  useEffect(() => {
    let unsub = null;

    if (currentUser) {
      unsub = onSnapshot(doc(firestore, "users", currentUser.email), (doc) => {
        if (doc.data().isDisabled && doc.data().isDisabled === true) {
          // get 'em outta heres...
          setMessage(
            "error",
            "Your account has been disabled by gallery administration. You may want to go talk to your building administrator.",
            7000
          );
          return setCurrentUser(null);
        }
      });
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const googleSignIn = async () => {
    await signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        // check that we have an aurora-schools.org account
        if (!emailDomainCheck(result.user.email)) {
          setMessage(
            "error",
            "Only Aurora City Schools learners and staff can sign in.",
            6000
          );

          return await signOut(auth)
            .then(() => {
              setCurrentUser(null);
            })
            .catch((error) => {
              console.error("There was an error signing out");
            });
        }

        // login success - be sure we have an updated user account
        await setDoc(
          doc(firestore, "users", result.user.email),
          {
            photoURL: result.user.photoURL,
            displayName: result.user.displayName,
          },
          { merge: true }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const googleSignOut = async () => {
    await signOut(auth)
      .then(() => {
        // clear current user
        setCurrentUser(null);

        // reset UI properties
        resetNavState();
      })
      .catch((error) => {
        console.error("There was an error signing out: ", error.message);
      });
  };

  const activateDeactivateUser = async (user) => {
    const userDocRef = doc(firestore, "users", user.id);
    try {
      if (user.isDisabled && user.isDisabled === true) {
        // we need to reactivate the user
        await updateDoc(userDocRef, {
          isDisabled: false,
        });

        setMessage(
          "success",
          "User has been reactivated and can now access the Gallery.",
          5000
        );
      } else {
        // we need to deactivate the user
        await updateDoc(userDocRef, {
          isDisabled: true,
        });

        setMessage(
          "success",
          "User has been disabled from accessing the Gallery. This action takes immediate effect, even if the user was signed in.",
          7000
        );
      }
    } catch (err) {
      setMessage(
        "error",
        "Error changing the activation status of the user: " + err.message,
        6000
      );
    }
  };

  const updatePolicyAcceptanceStatus = async (response) => {
    const userDocRef = doc(firestore, "users", currentUser.email);

    await updateDoc(userDocRef, {
      hasAcceptedResponsibleUsePolicy: response,
    });

    if (response === true) {
      setMessage(
        "success",
        "Thank you for accepting the Responsible Use Agreement. Welcome to the Greenhouse Gallery.",
        7000
      );
    } else {
      setMessage(
        "error",
        "Access Denied: You cannot participate on the Greehouse Gallery without accepting the responsible use agreement.",
        7000
      );
    }

    return null;
  };

  const value = {
    currentUser,
    googleSignIn,
    googleSignOut,
    activateDeactivateUser,
    updatePolicyAcceptanceStatus,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
