import { useState, useEffect, useContext } from "react";
import { useAuth } from "../contexts/AuthContext";
import { firestore } from "../firebaseInit";
import {
  doc,
  updateDoc,
  query,
  collection,
  where,
  onSnapshot,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { MessageContext } from "../contexts/MessageContext";
import { LoadingContext } from "../contexts/LoadingContext";

const useCollection = () => {
  const { currentUser } = useAuth();
  const { setMessage } = useContext(MessageContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [collectedImages, setCollectedImages] = useState([]);

  // establish listener for collection images
  useEffect(() => {
    setIsLoading(true);

    const q = query(
      collection(firestore, "images"),
      where("collectedBy", "array-contains", currentUser.email),
      where("isFlagged", "==", false),
      where("isCensored", "==", false)
    );

    const unsub = onSnapshot(q, (querySnapshot) => {
      const fetchedImages = [];
      querySnapshot.forEach((doc) => {
        fetchedImages.push({ id: doc.id, ...doc.data() });
      });

      setCollectedImages(fetchedImages);
    });

    setIsLoading(false);

    return unsub;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addImageToCollection = async (image) => {
    setIsLoading(true);

    try {
      const imageDocRef = doc(firestore, "images", image.id);

      // add the currentUser email to the image's collectedBy array
      await updateDoc(imageDocRef, {
        collectedBy: arrayUnion(currentUser.email),
      });

      setIsLoading(false);

      setMessage(
        "success",
        "The image has been added to your collection.",
        5000
      );
    } catch (err) {
      setMessage(
        "error",
        "There was an error adding the image to your collection: " + err,
        7000
      );

      setIsLoading(false);
    }
  };

  const removeImageFromCollection = async (image) => {
    setIsLoading(true);

    try {
      const imageDocRef = doc(firestore, "images", image.id);

      // remove the currentUser email from the image's collectedBy array
      await updateDoc(imageDocRef, {
        collectedBy: arrayRemove(currentUser.email),
      });

      setIsLoading(false);

      setMessage(
        "success",
        "The image has been removed from your collection.",
        5000
      );
    } catch (err) {
      setMessage(
        "error",
        "There was an error removing the image from your collection: " + err,
        7000
      );

      setIsLoading(false);
    }
  };

  return {
    collectedImages,
    addImageToCollection,
    removeImageFromCollection,
  };
};

export default useCollection;
