import { useState, useContext, useEffect } from "react";
import { MessageContext } from "../contexts/MessageContext";
import { Snackbar, Alert } from "@mui/material";

const MessagingPanel = () => {
  const { message } = useContext(MessageContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message && message.content) {
      setOpen(true);
    } else setOpen(false);
  }, [message]);

  return (
    message &&
    message.content && (
      <Snackbar
        open={open}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        sx={{ marginTop: "40px" }}
      >
        <Alert
          severity={
            message.severity !== "version" ? message.severity : "warning"
          }
          sx={{ width: "100%" }}
        >
          {message.content}
        </Alert>
      </Snackbar>
    )
  );
};

export default MessagingPanel;
