import { Grid, Dialog, DialogContent, Button, Typography } from "@mui/material";
import formatDate from "../utils/dateFormatter";
// import ImageDetail from "./ImageDetail";
import ImageItem from "./ImageItem";
import CommentPanel from "./CommentPanel";

const ImageDetailsDialog = ({ image, detailsOpen, handleCloseDetails }) => {
  return (
    <Dialog open={detailsOpen} onClose={handleCloseDetails} fullScreen>
      <DialogContent>
        <Grid item container direction="column">
          <Grid item container direction="column" sx={{ marginBottom: "2em" }}>
            <Grid item align="center">
              <Typography variant="h4">{`"${image.description}"`}</Typography>
            </Grid>
            <Grid item align="center">
              <Typography variant="body2" color="text.secondary">
                {`Posted by ${image.owner.displayName.slice(0, 1)}. ${
                  image.owner.displayName.split(" ")[1]
                } on ${formatDate(image.createdOn)}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item align="center" sx={{ marginBottom: "2em" }}>
            <Button onClick={handleCloseDetails} variant="contained">
              Close
            </Button>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            <Grid item md={6} sm={12} sx={{ marginBottom: "130px" }}>
              <ImageItem image={image} isSingular={true} isDetailsView={true} />
            </Grid>
            <Grid item md={6} sm={12}>
              <CommentPanel currentImage={image} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ImageDetailsDialog;
