import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  Chip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavContext } from "../contexts/NavContext";
import { useAuth } from "../contexts/AuthContext";
import useCollection from "../hooks/useCollection";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import AddToCollectionIcon from "@mui/icons-material/AddToPhotos";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CommentIcon from "@mui/icons-material/Comment";
import FlagIcon from "@mui/icons-material/Flag";
import ImageDetailsDialog from "./ImageDetailsDialog";
import CollectionRemovalDialog from "./CollectionRemovalDialog";
import DeleteImageDialog from "./DeleteImageDialog";
import FlagContentDialog from "./FlagContentDialog";
import imagePlaceholder from "../assets/imagePlaceholder.png";

const ImageItem = ({ image, isSingular = false, isDetailsView = false }) => {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const { navState } = useContext(NavContext);
  const { addImageToCollection } = useCollection();
  const [removeOpen, setRemoveOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [destroyOpen, setDestroyOpen] = useState(false);
  const [imageCanBeDestroyed, setImageCanBeDestroyed] = useState(true);
  const [uncontestedComments, setUncontestedComments] = useState([]);
  const [flagOpen, setFlagOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  // not allowing an image with flagged comments to be removed
  // also, determining comments that are not censored or flagged
  useEffect(() => {
    image.comments.map((comment) => {
      if (comment.isCensored || comment.isFlagged) {
        setImageCanBeDestroyed(false);
      } else {
        setImageCanBeDestroyed(true);
        setUncontestedComments([...uncontestedComments, comment]);
      }

      return null;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image.comments]);

  const handleRemoveModalOpen = () => {
    setRemoveOpen(true);
  };

  const handleRemoveModalClose = () => {
    setRemoveOpen(false);
  };

  const handleOpenDetails = () => {
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  const handleDestroyOpen = () => {
    setDestroyOpen(true);
  };

  const handleDestroyClose = () => {
    setDestroyOpen(false);
  };

  const handleFlagOpen = () => {
    setFlagOpen(true);
  };

  const handleFlagClose = () => {
    setFlagOpen(false);
  };

  return (
    <>
      <ImageListItem
        key={image.id}
        sx={
          isSingular && !isDetailsView
            ? {
                cursor: "pointer",
                width: isSmall ? "300px" : isMedium ? "500px" : "600px",
              }
            : isDetailsView
            ? {
                cursor: "default",
                paddingBottom: "40px",
              }
            : { cursor: "pointer" }
        }
      >
        <img
          src={imageLoading ? imagePlaceholder : `${image.imageLink}`}
          alt={image.title}
          onClick={isDetailsView ? null : handleOpenDetails}
          onLoad={() => setImageLoading(false)}
        />

        <ImageListItemBar
          sx={{ marginBottom: isSingular ? "40px" : 0 }}
          title={image.description}
          subtitle={`posted by ${image.owner.displayName}`}
        />
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          sx={{ backgroundColor: "lightgrey" }}
        >
          {!image.collectedBy.includes(currentUser.email) &&
            !navState.collectionActive && (
              <Grid item>
                <Tooltip title="Add to collection" placement="top">
                  <IconButton
                    onClick={() => addImageToCollection(image)}
                    aria-label="add-to-collection"
                  >
                    <AddToCollectionIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          {navState.collectionActive && (
            <Grid item>
              <Tooltip title="Remove from collection" placement="top">
                <IconButton
                  onClick={handleRemoveModalOpen}
                  aria-label="remove-from-collection"
                >
                  <PlaylistRemoveIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            {image.owner.email !== currentUser.email &&
            image.isResolved === false ? (
              <Tooltip title="Flag image as inappropriate" placement="top">
                <IconButton onClick={handleFlagOpen} aria-label="flag">
                  <FlagIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            ) : (
              image.isResolved === true && (
                <Tooltip
                  title="Image has been approved by moderator"
                  placement="top"
                >
                  <IconButton>
                    <VerifiedUserIcon sx={{ color: "blue" }} />
                  </IconButton>
                </Tooltip>
              )
            )}
          </Grid>
          {image.owner.email === currentUser.email && imageCanBeDestroyed && (
            <Grid item>
              <Tooltip title="Delete Image" placement="top">
                <IconButton onClick={handleDestroyOpen} aria-label="destroy">
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {!isDetailsView && (
            <Grid item>
              <Tooltip
                title={
                  uncontestedComments.length === 1
                    ? `${uncontestedComments.length} comment`
                    : `${uncontestedComments.length} comments`
                }
                placement="top"
              >
                <IconButton
                  onClick={handleOpenDetails}
                  aria-label="show-details"
                >
                  <CommentIcon
                    sx={{
                      color:
                        uncontestedComments.length === 0 ? "gray" : "green",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <ImageDetailsDialog
          image={image}
          detailsOpen={detailsOpen}
          handleCloseDetails={handleCloseDetails}
        />

        <CollectionRemovalDialog
          image={image}
          removeOpen={removeOpen}
          handleRemoveModalClose={handleRemoveModalClose}
        />

        <FlagContentDialog
          image={image}
          flagOpen={flagOpen}
          handleFlagClose={handleFlagClose}
        />

        <DeleteImageDialog
          image={image}
          destroyOpen={destroyOpen}
          handleDestroyClose={handleDestroyClose}
        />
      </ImageListItem>
      {isDetailsView ? (
        <Grid
          item
          container
          direction="row"
          spacing={1}
          sx={{ marginTop: "7px", maxWidth: "800px" }}
        >
          {image.tags.map((tag) => (
            <Grid item key={tag}>
              <Chip label={tag} />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </>
  );
};

export default ImageItem;
