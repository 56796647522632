import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { NavContext } from "../contexts/NavContext";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import GoogleIcon from "@mui/icons-material/Google";
import LogoutIcon from "@mui/icons-material/Logout";
import CollectionsIcon from "@mui/icons-material/Collections";

const NavDrawer = () => {
  const history = useHistory();
  const { setNavState } = useContext(NavContext);
  const { googleSignIn, googleSignOut, currentUser } = useAuth();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(!isDrawerOpen);
  };

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {currentUser && (currentUser.isModerator || currentUser.isSysAdmin) && (
          <ListItem button>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary={"Access Control"} />
          </ListItem>
        )}
        {currentUser && currentUser.isSysAdmin && (
          <ListItem
            button
            onClick={() => {
              setNavState({ evidencePanelIsOpen: true });
              history.push("/evidence-panel");
            }}
          >
            <ListItemIcon>
              <Inventory2Icon />
            </ListItemIcon>
            <ListItemText primary={"Evidence"} />
          </ListItem>
        )}
        {currentUser && (currentUser.isModerator || currentUser.isSysAdmin) && (
          <ListItem
            button
            onClick={() => {
              setNavState({ modPanelIsOpen: true });
              history.push("/moderator-panel");
            }}
          >
            <ListItemIcon>
              <DynamicFormIcon />
            </ListItemIcon>
            <ListItemText primary={"Moderate"} />
          </ListItem>
        )}
        {currentUser ? (
          <>
            <ListItem
              button
              onClick={() => {
                setNavState({ collectionActive: true });
                history.push(`/collection`);
              }}
            >
              <ListItemIcon>
                <CollectionsIcon />
              </ListItemIcon>
              <ListItemText primary={"My Collection"} />
            </ListItem>

            <ListItem
              button
              onClick={async () => {
                await googleSignOut();
                history.push("/");
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Sign Out"} />
            </ListItem>
          </>
        ) : (
          <ListItem
            button
            onClick={async () => {
              await googleSignIn();
              history.push("/home");
            }}
          >
            <ListItemIcon>
              <GoogleIcon />
            </ListItemIcon>
            <ListItemText primary={"Sign In"} />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <Grid item>
      <Button variant="contianed" color="secondary" onClick={toggleDrawer()}>
        <MenuIcon />
      </Button>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer()}>
        {list()}
      </Drawer>
    </Grid>
  );
};

export default NavDrawer;
