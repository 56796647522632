import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NavContext } from "../contexts/NavContext";
import { useAuth } from "../contexts/AuthContext";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Divider,
  InputAdornment,
  Paper,
} from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CloseAccessPanelIcon from "@mui/icons-material/CancelTwoTone";
import useAccessPanel from "../hooks/useAccessPanel";

const AccessPanel = () => {
  const { handleEmailSearch, searchError, setSearchError, foundUser } =
    useAccessPanel();

  const { setNavState } = useContext(NavContext);
  const { activateDeactivateUser } = useAuth();

  const history = useHistory();

  const [searchEmail, setSearchEmail] = useState("");
  const [searchEmailError, setSearchEmailError] = useState("");

  useEffect(() => {
    if (searchError.length > 0) {
      setSearchEmailError(searchError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEmailError]);

  const executeSearch = () => {
    if (searchEmail && searchEmail.length > 0) {
      setSearchError("");
      handleEmailSearch(searchEmail + "@aurora-schools.org");
      setSearchEmail("");
    }
  };

  const handleAccessPanelClose = () => {
    setNavState({ accessPanelIsOpen: false });
    setNavState({ evidencePanelIsOpen: false });
    setNavState({ modPanelIsOpen: false });
    history.push("/");
  };

  const handleActivateDeactivate = () => {
    activateDeactivateUser(foundUser);
  };

  return (
    <Container fixed>
      <Grid item container direction="column">
        <Grid item align="right" sx={{ marginTop: "10px" }}>
          <CloseAccessPanelIcon
            onClick={handleAccessPanelClose}
            sx={{ fontSize: "40px", cursor: "pointer" }}
          />
        </Grid>
        <Grid item align="center">
          <Typography gutterBottom variant="h2">
            Access Panel
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ fontSize: "20px" }}>
            As a moderator, you can control the access of users by either
            deactivating or reactivating them. When you select to deactivate a
            user, their access is immediately terminated, even if they are
            logged in at the time.
          </Typography>
        </Grid>
        <Divider
          sx={{ marginTop: "30px", marginBottom: "25px" }}
          variant="middle"
        />
        <Grid item container direction="row" alignItems="center" spacing={1}>
          <Grid item xs={2} align="center">
            <Typography>Email:</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              id="search-input"
              size="small"
              error={searchError.length > 0}
              value={searchEmail}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    @aurora-schools.org
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchEmail(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  executeSearch();
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={searchEmail.length === 0}
              color="secondary"
              aria-label="user search"
              variant="contained"
              startIcon={<PersonSearchIcon />}
              onClick={executeSearch}
            >
              Find
            </Button>
          </Grid>
        </Grid>
        {searchError.length > 0 && (
          <Grid item align="center">
            <Typography variant="body2" sx={{ color: "red" }}>
              {searchError}
            </Typography>
          </Grid>
        )}
      </Grid>
      {foundUser !== null && (
        <Grid item container direction="column" sx={{ marginTop: "30px" }}>
          <Paper sx={{ padding: "10px" }} elevation={2}>
            <Grid item container direction="row" alignItems="center">
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "18px" }}>
                  {foundUser.id}
                </Typography>
              </Grid>
              <Grid item xs={3} align="center">
                <Button variant="contained" onClick={handleActivateDeactivate}>
                  {foundUser.isDisabled ? "Reactivate" : "Deactivate"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item align="center" sx={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleAccessPanelClose}
        >
          Close Access Panel
        </Button>
      </Grid>
    </Container>
  );
};

export default AccessPanel;
