import { useState, useContext } from "react";
import {
  Grid,
  TextField,
  Button,
  Fab,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import useAddImage from "../hooks/useAddImage";
import { NavContext } from "../contexts/NavContext";

const AddImage = () => {
  const history = useHistory();
  const { addImage } = useAddImage();
  const { setNavState } = useContext(NavContext);

  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (image && description) {
      addImage({ image, description });
      setImage(null);
      setDescription("");
      // setNavState({ addImageActive: false });
      // history.push("/home");
    }
  };

  return (
    <Grid
      item
      container
      style={{
        marginTop: "5em",
      }}
      direction="column"
    >
      <Grid item container direction="column" alignItems="center">
        {image && (
          <Grid item sx={{ marginBottom: "1em" }}>
            <Card sx={{ maxWidth: 400 }}>
              <CardMedia
                component="img"
                image={URL.createObjectURL(image)}
                alt="image preview..."
              />
              <CardContent>
                <Typography noWrap variant="body2" component="div">
                  Image Upload Preview
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        {!image ? (
          <Grid item>
            <label htmlFor="upload-image">
              <input
                style={{ display: "none" }}
                id="upload-image"
                name="upload-image"
                type="file"
                onChange={handleFileChange}
              />

              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="upload-image-fab"
                variant="extended"
              >
                <AddIcon />{" "}
                <Typography variant="body2">Upload Image</Typography>
              </Fab>
            </label>
          </Grid>
        ) : (
          <Button onClick={() => setImage(null)}>Cancel Upload</Button>
        )}
        <Grid
          item
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            width: 400,
            maxWidth: "100%",
          }}
        >
          <TextField
            fullWidth
            id="description"
            label="Description"
            name="description"
            onChange={handleDescriptionChange}
          />
        </Grid>
        <Grid
          item
          sx={{
            marginTop: "20px",
            marginBottom: "5px",
          }}
        >
          <Button
            disabled={!description || !description.length > 0 || !image}
            fullWidth
            variant="contained"
            onClick={handleSubmit}
          >
            Post
          </Button>
        </Grid>
        <Grid
          item
          sx={{
            marginTop: "5px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setNavState({ addImageActive: false });
              history.push("/home");
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddImage;
