import { useState, useEffect } from "react";
import _ from "lodash";
import { firestore } from "../firebaseInit";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import ModeratorPanel from "../components/ModeratorPanel";

const withFlaggedItems = (BaseComponent) => (props) => {
  const [loading, setLoading] = useState(true);
  const [removalCache, setRemovalCache] = useState([]);
  const [flaggedItemsCache, setFlaggedItemsCache] = useState([]);
  const [flaggedItems, setFlaggedItems] = useState([]);

  const mergeFlaggedItems = () => {
    let newFlaggedItems = [...flaggedItems];

    flaggedItemsCache.map((flaggedItem) => {
      newFlaggedItems.push(flaggedItem);
      return null;
    });

    setFlaggedItems(_.orderBy(newFlaggedItems, ["createdOn"], ["asc"]));
    setFlaggedItemsCache([]);
  };

  // establish listener for flagged images
  useEffect(() => {
    setLoading(true);

    // establish properties of the snapshot listener
    // const collectionRef = collection(firestore, "images");
    const collectionRef = collection(firestore, "flaggingIncidents");
    const q = query(
      collectionRef,
      where("isResolved", "==", false),
      orderBy("createdOn", "asc")
    );

    // load added images into the newlyFlaggedItems
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          setFlaggedItemsCache((prevState) => [
            ...prevState,
            { id: change.doc.id, ...change.doc.data() },
          ]);
        }

        // Modifications are of no concern at this stage...

        if (change.type === "removed") {
          setRemovalCache((prevState) => [
            ...prevState,
            { id: change.doc.id, ...change.doc.data() },
          ]);
        }
      });

      setLoading(false);
    });

    // clean up the snapshot listener
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (removalCache.length > 0) {
      removalCache.map((removedItem) => {
        setFlaggedItems(
          flaggedItems.filter((item) => item.id !== removedItem.id)
        );
        setFlaggedItemsCache(
          flaggedItemsCache.filter((item) => item.id !== removedItem.id)
        );
        return null;
      });

      setRemovalCache([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removalCache.length]);

  return (
    <BaseComponent
      {...props}
      flaggedItems={flaggedItems}
      flaggedItemsCache={flaggedItemsCache}
      mergeFlaggedItems={mergeFlaggedItems}
      loading={loading}
    />
  );
};

const ModeratorPanelContainer = withFlaggedItems(ModeratorPanel);

export default ModeratorPanelContainer;
