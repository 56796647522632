import React from "react";
import { AppBar, Toolbar, Grid, Avatar, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import { useAuth } from "../contexts/AuthContext";
import galleryLogo from "../assets/galleryLogo.png";
import NavButtonBar from "./NavButtonBar";
import NavDrawer from "./NavDrawer";
import { localVersion } from "../config/localVersion";

const GalleryNavbar = () => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const engageDrawer = useMediaQuery("(max-width:1010px)");
  const tight = useMediaQuery(theme.breakpoints.down("md"));
  const tighter = useMediaQuery(theme.breakpoints.down("sm"));

  const NavTitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: tighter ? 25 : tight ? 35 : 40,
    fontWeight: 900,
    fontFamily: "Architects Daughter",
    color: theme.palette.secondary.light,
    marginLeft: "10px",
    lineHeight: "0.80em",
  }));

  return (
    <AppBar position="fixed">
      <Toolbar style={{ height: "100px" }}>
        <Grid item container direction="row" alignItems="center">
          <Grid item container direction="column" xs={4}>
            <Grid item container direction="row" alignItems="center">
              <Grid item xl={2} lg={3} sm={4} xs={4} align="center">
                <Avatar
                  alt="logo"
                  src={galleryLogo}
                  sx={{
                    width: tighter ? 45 : tight ? 60 : 80,
                    height: tighter ? 45 : tight ? 60 : 80,
                  }}
                />

                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {`v${localVersion}`}
                </Typography>
              </Grid>

              {currentUser && (
                <Grid item xl={10} lg={9} sm={8} xs={8}>
                  <NavTitleTypography>Greenhouse Gallery</NavTitleTypography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end" xs={8}>
            {engageDrawer ? <NavDrawer /> : <NavButtonBar />}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default GalleryNavbar;
